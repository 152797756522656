import { CalendarMonthOutlined, CleaningServices, CloseOutlined, Palette, PaletteOutlined, Refresh, TimerOutlined } from '@mui/icons-material';
import { Box, Menu, MenuItem, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import React, { useEffect } from 'react';
import * as Grey from '../StyledComponents';
import { AutomationType, IAutomationModel, ITimersAutomationModel, Scene, TimerState } from '../../models/Models';
import { appService } from '../../services/AppService';
import { ThemeService } from '../../services/ThemeService';

export default function GreySpeedDial(props: {
    automations?: IAutomationModel[],
    small: boolean,
    refresh: () => void,
    changeTheme: (theme: string) => void,
    error?: boolean,
}) {

    const [timerAutomation, setTimerAutomation] = React.useState(props.automations?.find(i => i.type === AutomationType.Timers) as ITimersAutomationModel);
    const [timers, setTimers] = React.useState<TimerState[]>(timerAutomation?.timers ?? []);
    const [open, setOpen] = React.useState(false);
    const handleOpenSpeedDial = () => setOpen(true);
    const handleCloseSpeedDial = () => setOpen(false);
    const [themeOpen, setThemeOpen] = React.useState(false);

    const speedDialAction = (action: string) => {
        if (action === "Maid") {
            appService.Scenes.toggle(Scene.Maid.toString());
        }
        else if (action === "garage_timer") {
            appService.startTimer("timers", "GarageTimerButton")
        }
        else if (action === "refresh") {
            props.refresh();
        }
        handleCloseSpeedDial();
    }

    const actions = [
        { icon: <Refresh sx={{ marginRight: '5px', marginBottom: '2px' }} />, name: 'Refresh', value: "refresh" },
        { icon: <CleaningServices sx={{ marginRight: '5px', marginBottom: '2px' }} />, name: 'Maid', value: "Maid" },
    ];

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl);
    const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const t = props.automations?.find(i => i.type === AutomationType.Timers) as ITimersAutomationModel;
        if (t) {
            setTimerAutomation(t);
            setTimers(t.timers)
        }
    }, [props.automations])

    if (props.small) {
        return <Box>
            <Grey.CustomIconButton sx={{ marginLeft: '10px' }} onClick={handleSettingsClick}><CalendarMonthOutlined /></Grey.CustomIconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={menuOpen}
                onClose={() => handleMenuClose()}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {actions.map(action => <MenuItem key={action.name} onClick={() => { handleMenuClose(); speedDialAction(action.value); }}>
                    {action.icon}{action.name}
                </MenuItem>)}
                {timers?.map(action => <MenuItem key={action.id} onClick={() => { handleMenuClose(); appService.startTimer(timerAutomation?.id ?? "", action.id); }}>
                    <TimerOutlined sx={{ marginRight: '5px', marginBottom: '2px' }} />{action.message}
                </MenuItem>)}
            </Menu>
        </Box>
    }
    return (<React.Fragment>
        <Grey.CustomSpeedDial
            ariaLabel="Actions"
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            onClose={handleCloseSpeedDial}
            onOpen={handleOpenSpeedDial}
            open={open}
            icon={<SpeedDialIcon icon={<CalendarMonthOutlined />} openIcon={<CloseOutlined />} />}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                    onClick={() => speedDialAction(action.value)}
                />
            ))}
        </Grey.CustomSpeedDial>
        {process.env.REACT_APP_IS_DEVELOPMENT === 'true' ?
            <Grey.CustomSpeedDial
                ariaLabel="Themes"
                sx={{ position: 'fixed', bottom: 16, right: 90 }}
                onClose={() => setThemeOpen(false)}
                onOpen={() => setThemeOpen(true)}
                open={themeOpen}
                icon={<SpeedDialIcon icon={<PaletteOutlined />} openIcon={<CloseOutlined />} />}
            >
                {ThemeService.GetThemes().concat('Default').map(theme => (
                    <SpeedDialAction
                        key={theme}
                        icon={<Palette />}
                        tooltipTitle={theme}
                        tooltipOpen
                        onClick={() => props.changeTheme(theme)} />
                ))}
            </Grey.CustomSpeedDial>
            : null}
    </React.Fragment>
    );
}