import { Chair, Hardware, HouseSharp, StairsOutlined } from '@mui/icons-material';
import { Box, CardContent } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import * as Grey from "../StyledComponents";

export function RoomsPage() {

    const rooms = [
        { Name: "All", Icon: <HouseSharp sx={{ marginRight: '5px' }} /> },
        { Name: "Living Room", Icon: <Chair sx={{ marginRight: '5px' }} /> },
        { Name: "Upstairs", Icon: <StairsOutlined sx={{ marginRight: '5px' }} /> },
        { Name: "Utilities", Icon: <Hardware sx={{ marginRight: '5px' }} /> },
    ];
    const navigate = useNavigate();

    return <Box>
        <Box className='grid' sx={{ marginLeft: '5px', marginRight: '10px' }}>
            {rooms?.map(room => {
                return <Grey.AutomationCard key={room.Name} className={`card`} sx={{ height: '150px', width: '100%', marginBottom: '8px', '& .MuiCardContent-root:last-child': { paddingBottom: '0' } }} variant="outlined">
                    <CardContent sx={{ padding: 0, width: '100%', height: '100%' }}>
                        <Grey.CustomButton sx={{ width: '100%', height: '100%' }} onClick={() => navigate(room.Name == 'All' ? '/' : `/d/${room.Name}`)}>{room.Icon}{room.Name}</Grey.CustomButton>
                    </CardContent>
                </Grey.AutomationCard>
            }
            )}
        </Box>
    </Box>;
}

