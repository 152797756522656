import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { IAutomationModel, IEventLogData } from '../../models/Models';
import { appService } from '../../services/AppService';
import { useEventHub } from '../../services/EventHub';

export default function LogsPage() {
    const [events, setEvents] = useState<IEventLogData[]>([]);

    useEffect(() => {
        async function load() {
            setEvents(await appService.getEvents() ?? []);
        }

        load();
    }, []);

    useEventHub<IAutomationModel>("eventlog", async updated => {
        setEvents([]);
        setEvents(await appService.getEvents() ?? []);
    });

    return (
        <Stack sx={{ textAlign: 'left', marginY: '15px', marginX: '10px' }}>
            {events.map(event => <Typography fontFamily='monospace' fontSize={14}>{dayjs(event.timestamp).format('h:mm:ss A')}: {event.message}</Typography>)}
        </Stack>
    );
}
