import React, { useContext } from "react";
import { IAutomationModel, SettingsModel } from "../models/Models";
import { RouteDictionary } from "./RouteDictionary";

export interface IAppContext {
    automations: IAutomationModel[] | null;
    settings: SettingsModel | null;
    routes: RouteDictionary;
}

export const AppContext = React.createContext<IAppContext>({
    automations: null,
    settings: null,
    routes: new RouteDictionary([], []),
});

export const useAppContext = () => useContext(AppContext);
