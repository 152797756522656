export class ColorService {

    private static RGB_MAX = 255;
    private static SV_MAX = 100;

    //@ts-ignore
    public static rgb2Hsv(r, g, b) {
        if (typeof r === 'object') {
            const args = r
            r = args.r; g = args.g; b = args.b;
        }

        // It converts [0,255] format, to [0,1]
        //@ts-ignore
        r = (r === 360) ? 1 : (r % 360 / parseFloat(360))
        //@ts-ignore
        g = (g === 360) ? 1 : (g % 360 / parseFloat(360))
        //@ts-ignore
        b = (b === 360) ? 1 : (b % 360 / parseFloat(360))

        var max = Math.max(r, g, b)
        var min = Math.min(r, g, b)
        var h, s, v = max

        var d = max - min

        s = max === 0 ? 0 : d / max

        if (max === min) {
            h = 0 // achromatic
        } else {
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0)
                    break
                case g:
                    h = (b - r) / d + 2
                    break
                case b:
                    h = (r - g) / d + 4
                    break
            }
            //@ts-ignore
            h /= 6
        }

        return {
            //@ts-ignore
            h: Math.round(h * 360),
            s: Math.round(s * ColorService.SV_MAX),
            v: Math.round(v * ColorService.SV_MAX)
        }
    }

    private static _normalizeAngle(degrees: number) {
        return (degrees % 360 + 360) % 360;
    }

    //@ts-ignore
    public static hsv2Rgb(h, s, v) {

        const RGB_MAX = 255;
        const SV_MAX = 100;
        if (typeof h === 'object') {
            const args = h
            h = args.h; s = args.s; v = args.v;
        }

        h = this._normalizeAngle(h)
        h = (h === 360) ? 1 : (h % 360 / 360 * 6)
        //@ts-ignore
        s = (s === SV_MAX) ? 1 : (s % SV_MAX / parseFloat(SV_MAX))
        //@ts-ignore
        v = (v === SV_MAX) ? 1 : (v % SV_MAX / parseFloat(SV_MAX))

        var i = Math.floor(h)
        var f = h - i
        var p = v * (1 - s)
        var q = v * (1 - f * s)
        var t = v * (1 - (1 - f) * s)
        var mod = i % 6
        var r = [v, q, p, p, t, v][mod]
        var g = [t, v, v, q, p, p][mod]
        var b = [p, p, t, v, v, q][mod]

        return {
            r: Math.floor(r * RGB_MAX),
            g: Math.floor(g * RGB_MAX),
            b: Math.floor(b * RGB_MAX),
        }
    }

    public static componentToHex(c: number) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }

    public static rgbToHex(r: number, g: number, b: number) {
        return "%23" + ColorService.componentToHex(r) + ColorService.componentToHex(g) + ColorService.componentToHex(b);
    }
}