import { AcUnitTwoTone, HistoryTwoTone, LocalFireDepartmentTwoTone, MotionPhotosAutoTwoTone, UpdateTwoTone } from "@mui/icons-material";
import { Box, CardContent, Slider, ToggleButtonGroup, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import React, { useState } from "react";
import { IAutomationModel, IClimateAutomationModel, OperationMode } from "../../models/Models";
import { appService } from "../../services/AppService";
import { useEventHub } from "../../services/EventHub";
import * as Grey from "../StyledComponents";
import { SettingsMenuButton } from "../configs/SettingsMenuButton";
import { PanelProps } from "./PanelProps";

function GetSliderColor(data: IClimateAutomationModel) {
    if (data.currentTemperature < data.targetTempLow) {
        return '#e37259';
    }
    if (data.currentTemperature > data.targetTempHigh) {
        return '#59a3e3';
    }
    return '#4bb88c';
}

function GetPulseColor(data: IClimateAutomationModel) {
    if (data.targetTempHigh == null || data.targetTempLow == null) {
        return '';
    }

    if (data.currentTemperature < data.targetTempLow) {
        return 'heatingFrames';
    }
    if (data.currentTemperature > data.targetTempHigh) {
        return 'coolingFrames'
    }
    return '';
}

function ClimatePanelSmall(props: PanelProps) {
    const [data, setData] = useState(props.data as IClimateAutomationModel);
    const [tempRange, setTempRange] = useState<number[]>([data.targetTempLow, data.targetTempHigh]);
    const [pulseColors, setPulseColors] = useState(GetPulseColor(data));
    const theme = useTheme();

    let modeIcon = null;
    let color = null;
    if (data.operationMode === OperationMode.Auto) {
        modeIcon = <MotionPhotosAutoTwoTone />;
        color = '#3d7a4a';
    }
    else if (data.operationMode === OperationMode.Cool) {
        modeIcon = <AcUnitTwoTone />;
        color = '#69a2ff';
    }
    else if (data.operationMode === OperationMode.Heat) {
        color = '#d96432';
    }
    else {
        modeIcon = theme.powerIcon;
        color = '#828282';
    }

    const updateAll = (updated: IAutomationModel | null) => {
        var model = updated as IClimateAutomationModel;
        if (model) {
            setData(model);
            setTempRange([model.targetTempLow, model.targetTempHigh]);
            setPulseColors(GetPulseColor(model))
        }
    }

    useEventHub<IClimateAutomationModel>(data.id, updateAll);

    const marks = [
        {
            value: data.currentTemperature,
            label: data.currentTemperature + '°',
        },
        {
            value: tempRange[0],
            label: tempRange[0] + '°',
        },
        {
            value: tempRange[1],
            label: tempRange[1] + '°',
        },
    ];

    const sliderStyle = {
        color: GetSliderColor(data),
        '& .Mui-disabled': { color: GetSliderColor(data) },
        '& .MuiSlider-track': { color: GetSliderColor(data) },
        '& .MuiSlider-markLabel[data-index="0"]': { color: 'green' },
        '& .MuiSlider-thumb': {
            width: 8,
            height: 8,
            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
            '&:before': {
                boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
            },
            '&:hover, &.Mui-focusVisible': {
                boxShadow: `0px 0px 0px 8px ${'rgb(255 255 255 / 16%)'
                    }`,
            }
        }
    };
    return <Box sx={{ flexGrow: 1, animation: `${pulseColors} 1.5s ease-in-out infinite alternate` }}>
        <Grey.CardTitleButton onClick={props.handleOpen}>
            <Box sx={{ flexGrow: 1 }}>
                <Grey.AutomationTitleSmall className='smallCardTitle' fontSize='14px' sx={{ borderBottom: '1px solid #333' }}>
                    {data?.title}
                </Grey.AutomationTitleSmall>
                <Grid marginX='10px' xs>
                    {data.operationMode == OperationMode.Off ? <Typography>{data.currentTemperature}°</Typography> : <Slider
                        disabled
                        color="primary"
                        sx={sliderStyle}
                        marks={marks}
                        min={tempRange[0] - 3}
                        max={tempRange[1] + 3}
                        getAriaLabel={() => 'Temperature range'}
                        value={tempRange}
                        step={1}
                        valueLabelDisplay="auto"
                    />}
                </Grid>
            </Box>
        </Grey.CardTitleButton>
    </Box>
}

function ClimatePanel(props: PanelProps) {

    const [data, setData] = useState<IClimateAutomationModel>(props.data as IClimateAutomationModel);
    const [tempRange, setTempRange] = useState<number[]>(data.operationMode === OperationMode.Auto ? [data.targetTempLow, data.targetTempHigh] : [data.targetTemp]);
    const [pulseColors, setPulseColors] = useState(GetPulseColor(data));
    const theme = useTheme();

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setTempRange(newValue as number[]);
    };

    const commitSliderChange = async (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
        const x = newValue as number[];
        if (data.operationMode === OperationMode.Auto) {
            var updated = await appService.Climate.setAutoTemperature(data.id, x[0], x[1]);
            if (updated) { setData(updated); setPulseColors(GetPulseColor(updated)); }
        }
    };

    const [operationMode, setOperationMode] = useState<OperationMode>(data.operationMode);
    const handleModeChange = async (event: React.MouseEvent<HTMLElement>, newMode: OperationMode) => {
        setOperationMode(newMode);
        var updated = await appService.Climate.changeMode(data.id, newMode);
        if (updated) { setData(updated); setTempRange([updated.targetTempLow, updated.targetTempHigh]); setPulseColors(GetPulseColor(updated)) }
    }

    let marks;
    if (data.operationMode === OperationMode.Auto) {
        marks = [
            {
                value: tempRange[0],
                label: tempRange[0] + '°',
            },
            {
                value: tempRange[1],
                label: tempRange[1] + '°',
            },
            {
                value: data.currentTemperature,
                label: data.currentTemperature + '°',
            },
        ];
    }
    else {
        marks = [
            {
                value: data.currentTemperature,
                label: data.currentTemperature + '°',
            },
            {
                value: tempRange[0],
                label: tempRange[0] + '°',
            },
        ];
    }

    const bumpHold = async (diff: number) => {
        var updated = await appService.Climate.increaseHold(data.id, diff);
        if (updated) { setData(updated); }
    }

    const updateAll = (updated: IAutomationModel | null) => {
        var model = updated as IClimateAutomationModel;
        if (model) {
            setData(model);
            setTempRange(model.operationMode === OperationMode.Auto ? [model.targetTempLow, model.targetTempHigh] : [model.targetTemp]);
            setOperationMode(model.operationMode);
            setPulseColors(GetPulseColor(model));
        }
    }

    useEventHub<IClimateAutomationModel>(data.id, updateAll);

    const sliderStyle = {
        color: GetSliderColor(data),
        marginTop: '5px',
        marginRight: '5px',
        '& .MuiSlider-markLabel[data-index="2"]': { color: 'green', background: `${theme.card.background}55`, borderRadius: '20px', paddingX: '5px' },
        '& .MuiSlider-thumb': {
            width: 12,
            height: 12,
            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
            '&:before': {
                boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
            },
            '&:hover, &.Mui-focusVisible': {
                boxShadow: `0px 0px 0px 8px ${'rgb(255 255 255 / 16%)'
                    }`,
            }
        }
    };

    return (<Grey.AutomationCard key={data.id} className={`card card-${theme.name}`} variant="outlined">
        <CardContent sx={{ animation: `${pulseColors} 1.5s ease-in-out infinite alternate`, height: '100%' }}>
            <Grey.AutomationTitle>
                {data.title}
                <SettingsMenuButton automation={data} />
                <Typography color={GetSliderColor(data)} sx={{ padding: 0, float: 'right', marginRight: '10px', marginTop: '2px' }}>{data.currentTemperature}°F</Typography>
            </Grey.AutomationTitle>
            <div style={{ textAlign: "left" }}>
                <Typography sx={{ fontSize: 14, textAlign: 'left' }} color="text.secondary" gutterBottom>
                    {data.status}
                </Typography>
                <div className='panel'>
                    <Grey.CustomSlider
                        sx={sliderStyle}
                        min={65}
                        max={80}
                        getAriaLabel={() => 'Temperature range'}
                        value={tempRange}
                        marks={marks}
                        step={1}
                        onChange={handleSliderChange}
                        onChangeCommitted={commitSliderChange}
                        valueLabelDisplay="auto"
                    />
                </div>
                <Box sx={{ flex: 'wrap', marginTop: '10px' }}>
                    <ToggleButtonGroup
                        sx={{ flexWrap: 'wrap', marginBottom: '10px', marginRight: '15px' }}
                        exclusive
                        color="info"
                        size="small"
                        value={operationMode}
                        onChange={handleModeChange}>
                        <Grey.CustomToggleButton value="Auto" aria-label="Default"><MotionPhotosAutoTwoTone color='success' /></Grey.CustomToggleButton>
                        <Grey.CustomToggleButton disabled value="Cool" aria-label="Default"><AcUnitTwoTone color='info' /></Grey.CustomToggleButton>
                        <Grey.CustomToggleButton disabled value="Heat" aria-label="Default"><LocalFireDepartmentTwoTone color='warning' /></Grey.CustomToggleButton>
                        <Grey.CustomToggleButton value="Off" aria-label="Default">{theme.powerIcon}</Grey.CustomToggleButton>
                    </ToggleButtonGroup>
                    <Grey.CustomIconButton sx={{ float: 'right' }} onClick={() => { bumpHold(15) }}><UpdateTwoTone /></Grey.CustomIconButton>
                    <Grey.CustomIconButton sx={{ float: 'right' }} onClick={() => { bumpHold(-15) }}><HistoryTwoTone /></Grey.CustomIconButton>
                </Box>
            </div>
        </CardContent>
    </Grey.AutomationCard>
    );
}

export { ClimatePanel, ClimatePanelSmall };

