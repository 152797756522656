import { Garage, GarageOutlined, LightbulbTwoTone, SensorOccupied, WarningAmber } from "@mui/icons-material";
import { Backdrop, Box, CardContent, CircularProgress, Stack, ToggleButtonGroup, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import React, { useState } from "react";
import { CoverStatus, IAutomationModel, IGarageAutomationModel } from "../../models/Models";
import { appService } from "../../services/AppService";
import { useEventHub } from "../../services/EventHub";
import * as Grey from "../StyledComponents";
import { PanelProps } from "./PanelProps";
import { ContactSensorView } from "./ContactSensorsPanel";

function Progress(props: { position: number }) {
    return <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress size='30px' variant="determinate" value={props.position} />
        <Box
            sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography
                variant="caption"
                component="div"
                color="text.secondary"
            >{`${Math.round(props.position)}%`}</Typography>
        </Box>
    </Box>
}

function DoorIcon(doorStatus: CoverStatus, position: number) {
    if (doorStatus === CoverStatus.Open) {
        if (position != 100 && position != 0) {
            return <Progress position={position} />
        }
        return <GarageOutlined color='success' />;
    }
    else if (doorStatus === CoverStatus.Closed) {
        return <Garage color='disabled' />;
    }
    else if (doorStatus === CoverStatus.Opening || doorStatus === CoverStatus.Closing) {
        if (position != 100 && position != 0) {
            return <Progress position={position} />
        }
        return <GarageOutlined color='info' />;
    }

    return <WarningAmber color='error' />;
}

function GaragePanelSmall(props: PanelProps) {
    const [data, setData] = useState(props.data as IGarageAutomationModel);
    const [doorStatus, setCoverStatus] = useState(data.doorStatus);
    const [isLoading, setIsLoading] = React.useState(false);
    const [icon, setIcon] = useState(DoorIcon(data.doorStatus, data.position));

    const handleDoorChange = async (
        event: React.MouseEvent<HTMLElement>,
        newValue: CoverStatus | null,
    ) => {
        setIsLoading(true);
        if (newValue === CoverStatus.Closed) {
            setCoverStatus(newValue);
            var updated = await appService.Covers.close(data.id);
            updateAll(updated);
        }
        else if (newValue === CoverStatus.Open) {
            setCoverStatus(newValue);
            var updated = await appService.Covers.open(data.id);
            updateAll(updated);
        }
        setIsLoading(false);
    };

    const updateAll = (updated: IAutomationModel | null) => {
        var model = updated as IGarageAutomationModel;
        if (model) {
            setData(model);
            setCoverStatus(model.doorStatus);
            setIcon(DoorIcon(model.doorStatus, model.position));
        }
    }

    useEventHub<IGarageAutomationModel>(data.id, updateAll);

    return <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Grey.CardTitleButton onClick={props.handleOpen}>
            <Grey.AutomationTitleSmall className='smallCardTitle' color='secondary' fontSize='14px'>
                {data?.title}
            </Grey.AutomationTitleSmall>
            <Stack direction='row' sx={{ position: 'absolute', right: '10px' }}>
                {icon}
            </Stack>
        </Grey.CardTitleButton>
        <Box sx={{ marginY: '10px', marginX: '10px', textAlign: 'left' }}>
            <ToggleButtonGroup
                sx={{ flexWrap: 'wrap' }}
                exclusive
                color="info"
                value={doorStatus}
                onChange={handleDoorChange}
                size="small">
                <Grey.CustomToggleButton value={CoverStatus.Open}>
                    Open
                </Grey.CustomToggleButton>
                <Grey.CustomToggleButton value={CoverStatus.Closed}>
                    Closed
                </Grey.CustomToggleButton>
            </ToggleButtonGroup>
        </Box>
        <Backdrop sx={{ position: 'absolute' }} open={isLoading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </Box>
}

function GaragePanel(props: PanelProps) {

    const theme = useTheme();
    const [data, setData] = useState(props.data as IGarageAutomationModel);
    const [doorStatus, setCoverStatus] = useState(data.doorStatus);
    const [lightColor, setLightColor] = useState(data.lightIsOn ? '#FFBB83' : theme.iconButton.color);
    const [icon, setIcon] = useState(DoorIcon(data.doorStatus, data.position));

    const handleDoorChange = async (
        event: React.MouseEvent<HTMLElement>,
        newValue: CoverStatus | null,
    ) => {
        if (newValue === CoverStatus.Closed) {
            setCoverStatus(newValue);
            var updated = await appService.Covers.close(data.id);
            updateAll(updated);
        }
        else if (newValue === CoverStatus.Open) {
            setCoverStatus(newValue);
            var updated = await appService.Covers.open(data.id);
            updateAll(updated);
        }
    };

    const toggleLight = async () => {
        updateAll(await appService.Covers.toggleLight(data.id, !data.lightIsOn));
    }

    const updateAll = (updated: IAutomationModel | null) => {
        var model = updated as IGarageAutomationModel;
        if (model) {
            setData(model);
            setCoverStatus(model.doorStatus);
            setLightColor(model.lightIsOn ? '#FFBB83' : theme.iconButton.color);
            setIcon(DoorIcon(model.doorStatus, model.position));
        }
    }

    useEventHub<IGarageAutomationModel>(data.id, updateAll);

    return (<Grey.AutomationCard key={data.id} className={`card card-${theme.name}`} variant="outlined">
        <CardContent>
            <Grey.AutomationTitle>
                {data.title}
                <Box sx={{ float: 'right' }}>{icon}</Box>
            </Grey.AutomationTitle>
            <div style={{ textAlign: "left", marginTop: '20px' }}>
                <Box >
                    {doorStatus === CoverStatus.Unknown ?
                        <Typography sx={{ verticalAlign: 'top' }}>Could not get garage door status!</Typography>
                        : (<ToggleButtonGroup
                            sx={{ flexWrap: 'wrap' }}
                            exclusive
                            color="info"
                            value={doorStatus}
                            onChange={handleDoorChange}
                            size="small">
                            <Grey.CustomToggleButton sx={{ width: '100px' }} value={CoverStatus.Open}>
                                Open
                            </Grey.CustomToggleButton>
                            <Grey.CustomToggleButton sx={{ width: '100px' }} value={CoverStatus.Closed}>
                                Closed
                            </Grey.CustomToggleButton>
                        </ToggleButtonGroup>)}
                    <Grey.CustomIconButton sx={{ float: 'right' }} onClick={toggleLight}>
                        <LightbulbTwoTone
                            sx={{ width: '40px', height: '40px', marginTop: '-8px' }}
                            htmlColor={lightColor} />
                    </Grey.CustomIconButton>
                </Box>
                <Typography sx={{ fontSize: 14, textAlign: 'left', marginTop: '15px' }} color="text.secondary" gutterBottom>
                    {data.status}
                </Typography>
                {data.motionDetected ?
                    <Stack direction='row' sx={{ marginTop: '10px' }}>
                        <SensorOccupied color='warning' />
                        <Typography sx={{ marginTop: '2px', marginLeft: '10px' }}>Motion detected</Typography>
                    </Stack>
                    : null}
                {data.isObstructed ?
                    <Stack direction='row' sx={{ marginTop: '10px' }}>
                        <WarningAmber color='error' />
                        <Typography sx={{ marginTop: '2px', marginLeft: '10px' }}>Door is obstructed!</Typography>
                    </Stack>
                    : null}
                {data.interiorDoor ? <ContactSensorView sx={{ marginTop: '25px' }} sensor={data.interiorDoor} /> : null}

            </div>
        </CardContent>
    </Grey.AutomationCard>
    );
}
export { GaragePanel, GaragePanelSmall };

