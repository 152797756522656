import { useWindowFocus } from '@better-hooks/window';
import { Hardware, HomeTwoTone, Lightbulb, LightbulbTwoTone, LocalFloristTwoTone, LocalMovies, NotesSharp, NotesTwoTone, RoomSharp, SettingsTwoTone, TvTwoTone, VideoCameraBack, VideoCameraBackTwoTone } from '@mui/icons-material';
import { Box, CssBaseline, Theme, ThemeProvider } from '@mui/material';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import './App.css';
import './Clouds.css';
import './Rain.scss';
import GreySpeedDial from './components/controls/GreySpeedDial';
import ResponsiveDrawer from './components/controls/ResponsiveDrawer';
import ResponsiveDrawerSmall from './components/controls/ResponsiveDrawerSmall';
import { CamerasPage } from './components/pages/CamerasPage';
import { IAutomationModel, IWeatherAutomationModel, RouteInfo, SettingsModel } from './models/Models';
import { AppContext } from './services/AppContext';
import { appService } from './services/AppService';
import { configService } from './services/ConfigService';
import { eventHubInstance, useEventHub } from './services/EventHub';
import { RouteDictionary } from './services/RouteDictionary';
import { ThemeService } from './services/ThemeService';
import { delay } from './services/Utils';

const GetTheme = (weather?: IWeatherAutomationModel) => {
  const savedTheme = localStorage?.getItem('theme');
  if (savedTheme) {
    return ThemeService.GetThemeByName(savedTheme);
  }

  if (!weather) {
    const lastTheme = localStorage?.getItem("lastTheme");
    if (lastTheme) {
      return ThemeService.GetThemeByName(lastTheme);
    }

    return ThemeService.GetDefaultTheme();
  }

  const currentTime = dayjs();
  const isDay = currentTime > dayjs(weather.today.sunrise).add(2, 'hour')
    && currentTime < dayjs(weather.today.sunset).add(-1, 'hour');

  const newTheme = ThemeService.GetDefaultTheme(isDay);
  return newTheme;
}

function App(props: { error?: boolean }) {
  const [automations, setAutomations] = useState(new Array<IAutomationModel>());
  const [settings, setSettings] = useState<SettingsModel | null>(null);
  const [theme, setTheme] = useState(GetTheme());
  const htmlEl = document.getElementById('html') as HTMLElement;
  htmlEl.style.backgroundColor = theme.page.background;

  const updateTheme = (newTheme: Theme) => {
    if (newTheme.name != theme.name) {
      setTheme(newTheme);
      const htmlEl = document.getElementById('html') as HTMLElement;
      htmlEl.style.backgroundColor = newTheme.page.background;
      if (localStorage) {
        localStorage.setItem("lastTheme", newTheme.name);
      }
    }
  }

  useEffect(() => {
    async function load() {

      while (true) {
        try {
          console.log('attempting to get automations...');
          setSettings(await configService.GetSettings());
          const automations = await appService.Automations.getAutomations();
          updateTheme(GetTheme(automations.find(i => i.id == 'weather') as IWeatherAutomationModel))
          setAutomations(automations);
          return;
        }
        catch {
          console.log('failed to load automations. retrying...');
          await delay(5000);
          if (process.env.REACT_APP_IS_DEVELOPMENT === 'false') {
            return;
          }
        }
      }
    }

    load();
  }, []);

  const refresh = async () => {
    eventHubInstance.Reconnect();
    setAutomations([]);
    setAutomations(await appService.Automations.getAutomations());
  }

  const [eventHubConnected, setEventHubConnected] = useState(false);

  const focus = useWindowFocus()
  useEffect(() => {
    if (focus) {
      eventHubInstance.Reconnect();
    } else {
      // User has minimized window or leaved our page to different tab
    }
  }, [focus])


  const saveNewTheme = (theme: string) => {
    if (theme.toLocaleLowerCase() === 'default') {
      localStorage?.removeItem('theme');
      updateTheme(GetTheme(automations.find(i => i.id == 'weather') as IWeatherAutomationModel))
      return;
    }

    localStorage?.setItem('theme', theme);
    updateTheme(ThemeService.GetThemeByName(theme));
  }

  useEventHub<IWeatherAutomationModel>('weather', updated => {
    updateTheme(GetTheme(updated));
  });

  const reconnect = () => eventHubInstance.Reconnect().then(i => setEventHubConnected(true));

  //@ts-ignore
  window['reload'] = reconnect;

  const routes = [{
    Name: "Home",
    Path: "/home",
    Icon: <HomeTwoTone htmlColor='#418763' />,
    Color: '#f4b8e7',
  } as RouteInfo,
  {
    Name: "Cameras",
    Path: "/cameras",
    Icon: <VideoCameraBackTwoTone htmlColor='#cc543f' />,
    Color: '#edb0ea',
  },
  {
    Name: "Lights",
    Path: "/t/Lights",
    Icon: <LightbulbTwoTone htmlColor='#a8a432' />,
    Color: '#d8a2f0',
  },
  {
    Name: "Settings",
    Path: "/settings",
    Icon: <SettingsTwoTone htmlColor='#487b9c' />,
    Color: '#c89cee',
  },
  {
    Name: "Watchlist",
    Path: "/watchlist",
    Icon: <LocalMovies htmlColor='#487b9c' />,
    Color: '#b696e9',
  },
  {
    Name: "Logs",
    Path: "/logs",
    Icon: <NotesSharp htmlColor='#417a87' />,
    Color: '#b696e9',
  }];

  const routesSmall = [{
    Name: "Home",
    Path: "/home",
    Icon: <HomeTwoTone htmlColor='#ba84bd' />,
    ShowInTop: true,
  } as RouteInfo,
  {
    Name: "Lights",
    Path: "/t/Lights",
    Icon: <Lightbulb htmlColor='#db9e42' />,
    ShowInTop: true,
  } as RouteInfo,
  {
    Name: "Plants",
    Path: "/d/Plants",
    Icon: <LocalFloristTwoTone htmlColor='#4bb88c' />,
    ShowInTop: true,
  } as RouteInfo,
  {
    Name: "Cameras",
    Path: "/cameras",
    Icon: <VideoCameraBack htmlColor='#a83b39' />,
    Content: <CamerasPage />,
    ShowInTop: true,
  },
  {
    Name: "Utilities",
    Path: "/d/utilities",
    Icon: <Hardware />,
  } as RouteInfo,
  {
    Name: "Rooms",
    Path: "/rooms",
    Icon: <RoomSharp />,
    Color: '#e4a8ef',
  } as RouteInfo,
  {
    Name: "Watchlist",
    Path: "/watchlist",
    Icon: <LocalMovies />,
    Color: '#b696e9',
  },
  {
    Name: "Logs",
    Path: "/logs",
    Icon: <NotesTwoTone />,
    Color: '#b696e9',
  }];

  return (
    <AppContext.Provider value={{ automations, settings, routes: new RouteDictionary(routes.concat(routesSmall), automations) }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="App">
          <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
            {ThemeService.GetBackgroundImageSmall(theme)}
            <ResponsiveDrawerSmall routes={routesSmall} refresh={refresh} automations={automations} changeTheme={saveNewTheme} />
          </Box>
          <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' }, overflow: 'hidden' }}>
            {ThemeService.GetBackgroundImageLarge(theme)}
            <ResponsiveDrawer routes={routes} error={props.error} />
            <GreySpeedDial small={false} refresh={refresh} changeTheme={saveNewTheme} error={props.error} />
          </Box>
        </div>
      </ThemeProvider>
    </AppContext.Provider>
  );
}

export default App;
