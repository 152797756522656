import { useRouteError } from 'react-router-dom';

export default function ErrorPage() {
    const error = useRouteError() as { statusText: string, message: string };

    return (<div style={{ marginLeft: '10px' }}>
        <h1>Oopsie woopsie!</h1>
        <p>
            <i>{error.statusText || error.message}</i>
        </p>
    </div>
    )
}
