import { LargeAutomationCard } from "../components/cards/CardMap";
import { IAutomationModel, RouteInfo } from "../models/Models";

export class RouteDictionary {

    private routes: RouteInfo[];
    private automations: IAutomationModel[];

    constructor(routes: RouteInfo[], automations: IAutomationModel[]) {
        this.routes = routes;
        this.automations = automations;
    }

    public getCurrentRouteInfo() {
        if (window.location.pathname.startsWith('/a')) {
            const automation = this.automations.find(i => i.id == window.location.pathname.slice(3))
            if (automation) {
                return {
                    Name: automation.title,
                    Path: automation.id,
                    Meta: automation,
                    Content: LargeAutomationCard({ data: automation }),
                } as RouteInfo
            }
        }

        const r = this.routes.find(i => window.location.pathname.startsWith(i.Path)) ?? this.routes[0];
        if (r.Name == 'Rooms') {
            const roomName = decodeURIComponent(window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1));
            if (roomName.length > 0) return { Name: roomName.substring(0, 1).toUpperCase() + roomName.substring(1) };
        }

        return r;
    }
}
