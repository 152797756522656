import { AirTwoTone, ArrowDownward, ArrowUpward, BlindsTwoTone, MenuSharp, ThermostatTwoTone, WaterDropTwoTone } from "@mui/icons-material";
import { Box, CardContent, Grid, Popover, Slider, Stack, styled, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from "react";
import { BlindsStatus, IAutomationModel, IBlindsAutomationModel, IBlindsState } from "../../models/Models";
import { appService } from "../../services/AppService";
import { useEventHub } from "../../services/EventHub";
import * as Grey from "../StyledComponents";
import { SettingsMenuButton } from "../configs/SettingsMenuButton";
import { PanelProps } from "./PanelProps";

function BlindsPanelSmall(props: PanelProps) {
    const [data, setData] = useState(props.data as IBlindsAutomationModel);
    const [isLoading, setIsLoading] = React.useState(data.mainStatus == BlindsStatus.Moving);
    const [position, setPosition] = React.useState(100 - data.tiltPosition);
    const [angle, setAngle] = React.useState(`rotate(${GetAngle(data.tiltPosition)}deg)`);

    const updateAll = (updated: IAutomationModel | null) => {
        var model = updated as IBlindsAutomationModel;
        if (model) {
            setData(model);
            setPosition(100 - model.tiltPosition);
            setAngle(`rotate(${GetAngle(model.tiltPosition)}deg)`);
            setIsLoading(model.mainStatus == BlindsStatus.Moving);
        }
    }

    useEventHub<IBlindsAutomationModel>(data.id, updateAll);

    const handleSliderChange = async (event: Event, newValue: number | number[]) => {
        setPosition(newValue as number);
    };

    const commitSliderChange = async (event: React.SyntheticEvent | Event | null, newValue: number | number[]) => {
        setIsLoading(true);
        const model = await appService.Covers.setPosition(data.id, 100 - (newValue as number));
        updateAll(model);
    };

    if (data.mainStatus === BlindsStatus.None) {
        return <Box sx={{ flexGrow: 1, position: 'relative', ...props.sx }}>
            {!props.forRoom ? <Grey.CardTitleButton onClick={props.handleOpen}>
                <Grey.AutomationTitleSmall className='smallCardTitle' color='secondary' fontSize='14px'>
                    {data?.title}
                </Grey.AutomationTitleSmall>
            </Grey.CardTitleButton> : null}
            <Stack direction='row' sx={{ margin: '5px' }}>
                {data.blinds[0].windowTemperature ?
                    <Box sx={{ marginRight: '10px' }}>
                        <ThermostatTwoTone htmlColor="#eb8034" />
                        <Typography>{data.blinds[0].windowTemperature}°F</Typography>
                    </Box> : null
                }
                {data.blinds[0].windowHumidity ?
                    <Box sx={{ marginRight: '10px' }}>
                        <WaterDropTwoTone htmlColor="#2b78c4" />
                        <Typography>{data.blinds[0].windowHumidity}%</Typography>
                    </Box> : null
                }
                {data.blinds[0].windowPressure ?
                    <Box sx={{ marginRight: '10px' }}>
                        <AirTwoTone htmlColor="#929da8" />
                        <Typography>{data.blinds[0].windowPressure} mbar</Typography>
                    </Box> : null
                }
            </Stack>
        </Box>
    }

    if (props.forRoom) {
        return <Box sx={{ flexGrow: 1, position: 'relative', ...props.sx }}>
            <Grid container spacing={1} rowSpacing='2px' alignItems="center" marginY='1px' marginRight='5px'>
                <Grid xs='auto' item>
                    <Grey.CustomIconButton
                        sx={{ marginLeft: '5px' }}
                        onClick={props.handleOpen}>
                        <BlindsTwoTone color='secondary' />
                    </Grey.CustomIconButton>
                </Grid>
                <Grid xs item sx={{ marginTop: '4px', marginLeft: '10px', marginRight: '15px' }}>
                    <Grey.CustomSlider
                        sx={{}}
                        valueLabelDisplay="auto"
                        disabled={isLoading}
                        onChange={handleSliderChange}
                        onChangeCommitted={commitSliderChange}
                        value={position}
                        min={5}
                        max={95}
                        step={1} />
                </Grid>
                <MenuSharp color='warning' sx={{ transform: angle, marginRight: '13px', marginTop: '0px' }} />
            </Grid>
        </Box>
    }

    return <Box sx={{ flexGrow: 1, position: 'relative', ...props.sx }}>
        <Grey.CardTitleButton onClick={props.handleOpen}>
            <Grey.AutomationTitleSmall className='smallCardTitle' color='secondary' fontSize='14px'>
                {data?.title}
            </Grey.AutomationTitleSmall>
            <MenuSharp color='warning' sx={{ transform: angle, position: 'absolute', right: '10px' }} />
        </Grey.CardTitleButton>
        <Grid container spacing={1} alignItems="center" marginY='1px'>
            <Grey.CustomIconButton disabled={isLoading} sx={{ marginTop: '-1px', marginLeft: '13px', marginRight: '5px' }} onClick={() => commitSliderChange(null, position - 10)}>
                <ArrowUpward />
            </Grey.CustomIconButton>
            <Grid xs item sx={{ marginTop: '4px' }}>
                <Grey.CustomSlider valueLabelDisplay="auto" disabled={isLoading} onChange={handleSliderChange} onChangeCommitted={commitSliderChange} value={position} min={5} max={95} step={1} />
            </Grid>
            <Grey.CustomIconButton disabled={isLoading} sx={{ marginTop: '-1px', marginLeft: '5px', marginRight: '6px' }}
                onClick={() => commitSliderChange(null, position + 10)}>
                <ArrowDownward />
            </Grey.CustomIconButton>
        </Grid>
    </Box>
}

interface BlindsSettingProps {
    automationState: IBlindsAutomationModel,
    blinds: IBlindsState,
}

const BlindsSlider = styled(Slider)({
    color: 'transparent',
    height: 8,
    borderRadius: 0,
    padding: 0,
    border: 'none',
    '& .MuiSlider-rail': {
        backgroundImage: 'linear-gradient(to bottom, #f8bfbf, #f8b0bd, #f5a2c1, #ec96c9, #dc8dd5, #cd8fe0, #bb92eb, #a496f4, #95a1fb, #87abfe, #7bb5ff, #74bdff)',
        opacity: '1',
    },
    '& .MuiSlider-track': {
        border: 'none',
        width: '60px',
    },
    '& .MuiSlider-thumb': {
        height: 15,
        width: 40,
        zIndex: 20,
        backgroundColor: '#444444',
        borderRadius: '15px',
        border: '2px solid #dfdfdf',
        '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
            boxShadow: 'inherit',
        },
        '&::before': {
            display: 'none',
        },
    },
});

const getBackgroundColor = (blinds: IBlindsState) => {
    if (blinds.status == BlindsStatus.Up) {
        return 'linear-gradient(#121212, #777777)'
    }
    else if (blinds.status == BlindsStatus.Down) {
        return 'linear-gradient(#777777, #121212)'
    }
    return '#123123'
}

function GetAngle(position: number) {
    const maxAngle = 65;
    var angle = (position / 100) * maxAngle * 2;

    var r = angle - maxAngle;
    if (r > -8 && r < 8) {
        return 0;
    }

    r = r * 1.3
    return r < -maxAngle ? -maxAngle : (r > maxAngle ? maxAngle : r);
}

function GetSlatHeight(position: number) {
    const angle = GetAngle(position);
    return ((Math.abs(angle) / 65) * 8) + 3;
}

function BlindsControl(props: BlindsSettingProps) {
    const [blinds, setBlinds] = useState(props.blinds);
    const [position, setPosition] = useState(blinds.tiltPosition ?? 50);
    const [slatHeight, setSlatHeight] = useState(GetSlatHeight(position ?? 50))
    const [background, setBackground] = useState(getBackgroundColor(blinds));
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const open = Boolean(anchorEl);

    const updateAll = (model: IBlindsState | undefined) => {
        if (model) {
            setBlinds(model);
            setPosition(model.tiltPosition ?? 50);
            setSlatHeight(GetSlatHeight(model.tiltPosition ?? 50));
            setBackground(getBackgroundColor(model));
        }
    }

    const handleSliderChange = async (event: Event, newValue: number | number[]) => {
        setPosition(newValue as number);
        setSlatHeight(GetSlatHeight(newValue as number));
    };

    const commitSliderChange = async (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
        const model = await appService.Covers.setPosition(props.automationState.id, newValue as number, blinds.coverEntityId);
        const match = model?.blinds.find(i => i.coverEntityId == blinds.coverEntityId);
        updateAll(match);
    };

    useEffect(() => {
        updateAll(props.blinds);
    }, [props.blinds])

    if (blinds.status == BlindsStatus.Unknown) {
        return <Typography sx={{ verticalAlign: 'top' }}>Could not get status!</Typography>
    }

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    return <Stack direction='column'>
        <Box sx={{ position: 'relative', height: '150px', overflow: 'hidden', marginRight: '20px', border: '2px solid #dfdfdf' }}
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}>
            <BlindsSlider
                className='blindsSlider'
                valueLabelDisplay="off"
                size='medium'
                onChange={handleSliderChange}
                onChangeCommitted={commitSliderChange}
                value={position}
                min={5}
                max={95}
                step={1}
                orientation="vertical"
                sx={{ height: '150px', width: '60px', pointerEvents: blinds.status === BlindsStatus.None ? 'none' : null, '& .MuiSlider-thumb': { display: blinds.status === BlindsStatus.None ? 'none' : null } }} />
            {Array(10).fill(0).map((i, index) =>
                <div
                    key={index}
                    style={{
                        position: 'absolute', top: `${(index * 15) + 2}px`,
                        left: '0px',
                        width: '60px',
                        height: `${slatHeight}px`,
                        background: background,
                        pointerEvents: 'none'
                    }} />)}
        </Box>
        {blinds.windowIsOpened ? <Typography fontSize={15} marginTop='5px'>Opened</Typography> : null}
        {!blinds.windowIsOpened && blinds.status === BlindsStatus.None ? <Typography fontSize={15} marginTop='5px'>Closed</Typography> : null}
        <Popover
            id="mouse-over-popover"
            sx={{
                pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
        >
            {blinds.tiltPosition ? <Typography sx={{ p: 1 }}>Position: {blinds.tiltPosition}%</Typography> : null}
            {blinds.battery ? <Typography sx={{ p: 1 }}>Battery: {blinds.battery}%</Typography> : null}
            {blinds.windowTemperature ? <Typography sx={{ p: 1 }}>Temperature: {blinds.windowTemperature}°F</Typography> : null}
            {blinds.windowHumidity ? <Typography sx={{ p: 1 }}>Humidity: {blinds.windowHumidity}%</Typography> : null}
        </Popover>
    </Stack>;
}

function BlindsPanel(props: PanelProps) {

    const [data, setData] = useState(props.data as IBlindsAutomationModel);
    const [position, setPosition] = useState(data.tiltPosition);
    const theme = useTheme();

    const updateAll = (updated: IAutomationModel | null) => {
        var model = updated as IBlindsAutomationModel;
        if (model) {
            setData(model);
            setPosition(model.tiltPosition);
        }
    }

    const handleSliderChange = async (event: Event, newValue: number | number[]) => {
        setPosition(newValue as number)
    };

    const commitSliderChange = async (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
        const model = await appService.Covers.setPosition(data.id, newValue as number);
        updateAll(model);
    };

    useEventHub<IBlindsAutomationModel>(data.id, updateAll);

    return (<Grey.AutomationCard key={data.id} className={`card card-${theme.name}`} variant="outlined">
        <CardContent>
            <Grey.AutomationTitle>
                {data.title}
                <SettingsMenuButton automation={data} />
            </Grey.AutomationTitle>
            <div style={{ textAlign: "left" }}>
                <Stack direction='row' sx={{ flex: 'wrap', marginTop: '20px' }}>
                    {data.mainStatus === BlindsStatus.None ? null :
                        <Slider
                            size='small'
                            onChange={handleSliderChange}
                            onChangeCommitted={commitSliderChange}
                            value={position}
                            min={5}
                            max={95}
                            step={1}
                            orientation="vertical"
                            sx={{ height: '150px', marginRight: '10px' }} />
                    }
                    {data.blinds.map(blinds => <BlindsControl key={blinds.coverEntityId ?? blinds.contactEntityId} automationState={data} blinds={blinds} />)}
                </Stack>
                {data.mainStatus === BlindsStatus.None ? null :
                    <Typography sx={{ fontSize: 14, textAlign: 'left', marginTop: '10px' }} color="text.secondary" gutterBottom>
                        {data.status}
                    </Typography>
                }
            </div>
        </CardContent>
    </Grey.AutomationCard>
    );
}
export { BlindsPanel, BlindsPanelSmall };

