import { Box } from '@mui/material';

export default function KibanaPage() {
    return (
        <Box>
            <iframe
                style={{ position: 'relative', width: '100%', height: '1000px' }}
                src="https://kibana.thinkgrey.ai/app/dashboards#/view/5a72aad0-fc9e-11ed-b84c-4ddbab80856d?embed=true&_g=(refreshInterval%3A(pause%3A!t%2Cvalue%3A60000)%2Ctime%3A(from%3Anow-15m%2Cto%3Anow))&hide-filter-bar=true"></iframe>
        </Box>
    );
}
