import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Outlet, useNavigate } from 'react-router-dom';
import { IAutomationModel, RouteInfo } from '../../models/Models';
import ErrorPage from '../pages/ErrorPage';

const drawerWidth = 180;

interface Props {
  routes: RouteInfo[];
  error?: boolean;
}

export default function ResponsiveDrawer(props: Props) {
  const routes = props.routes;
  const theme = useTheme();

  const navigate = useNavigate();

  const getUnselectedGradient = (route: RouteInfo) => {
    if (theme.palette.mode == 'light') {
      return `linear-gradient(to right, ${route.Color}aa, ${route.Color}aa)`
    }
    return `linear-gradient(to right, ${route.Color}33, ${route.Color}22)`
  }

  const getSelectedGradient = (route: RouteInfo) => {
    if (theme.palette.mode == 'light') {
      return `linear-gradient(to right, #ADE3EE, #66ACD8)`
    }
    return `linear-gradient(to right, ${route.Color}66, #AD93E7)`
  }

  const selected = (route: RouteInfo) => {
    return {
      backgroundImage: getSelectedGradient(route),
      color: '#fff',
      '&:hover': { backgroundColor: `${route.Color}ee`, color: '#fff' }
    }
  }

  const unSelected = (route: RouteInfo) => {
    return {
      backgroundImage: getUnselectedGradient(route),
      color: theme.palette.mode == 'light' ? '#255570' : '#fff',
      '&:hover': { backgroundColor: `${route.Color}aa`, color: theme.palette.mode == 'light' ? '#255570' : '#fff' }
    }
  }

  const isSelected = (path: string) => {
    const isHome = (p: string) => p == '/' || p == '/home' || p.startsWith('/d/');
    const currentPath = isHome(window.location.pathname.toLocaleLowerCase()) ? '/home' : window.location.pathname;
    return currentPath.toLocaleLowerCase().startsWith(path.toLocaleLowerCase());
  }

  const aeroStyle = {
    boxSizing: 'border-box',
    position: 'fixed',
    top: '10px',
    left: '10px',
    width: drawerWidth - 10,
    borderRadius: '20px',
    background: '#0000aa11',
    backdropFilter: 'blur(5px)',
    border: '1px solid rgba(240, 176, 253, 0.2)',
    boxShadow: '5px 5px 5px 0px rgba(0,0,0,0.3)',
    '& .MuiListItemButton-root': {
      margin: '5px',
      borderRadius: '10px',
    }
  };
  const normalStyle = {
    background: theme.appBar.background,
    border: 'none',
    boxSizing: 'border-box',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    width: drawerWidth,
    boxShadow: theme.appBar.boxShadow ?? '0px 0px 5px #27768c',
    '& .MuiListItemButton-root': {
      borderBottom: `1px outset ${routes[0].Color}`
    }
  };

  const aeroBackgroundStyle = {
    height: '100%',
    width: '100%',
    position: 'fixed',
    display: 'flex',
    backgroundColor: theme.page.background,
    backgroundImage: theme.page.backgroundImage,
    backgroundSize: 'cover',
  };
  const normalBackgroundStyle = {
    backgroundColor: theme.page.background,
    display: 'flex',
  };

  return (
    //@ts-ignore
    <Box sx={theme.name == 'Aero' ? { ...aeroBackgroundStyle } : { ...normalBackgroundStyle }}>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        <Box sx={theme.name == 'Aero' ? { ...aeroStyle } : { ...normalStyle }}>
          <List>
            {routes.map(route => (
              <ListItem key={route.Name} disablePadding>
                <ListItemButton onClick={() => navigate(route.Path)} sx={{
                  ...(isSelected(route.Path) ? selected(route) : unSelected(route))
                }}>
                  <ListItemIcon>
                    {route.Icon}
                  </ListItemIcon>
                  <ListItemText primary={route.Name} sx={{
                    '& .MuiTypography-root':
                      { fontWeight: isSelected(route.Path) ? 'bold' : '' }
                  }} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 0, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
      >
        {props.error ? <ErrorPage /> : <Outlet />}
      </Box>
    </Box>
  );
}
