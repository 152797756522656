import { Backdrop, Box, CircularProgress, Tab, Tabs, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import { AutomationType, DayOfWeek, GroupType, IAutomationModel, ScheduleConfigModel, SetpointSetting } from "../../models/Models";
import { configService } from "../../services/ConfigService";
import { ScheduleDay } from "./ScheduleDay";

interface IData {
    automation: IAutomationModel,
    data: ScheduleConfigModel | null,
    type: AutomationType,
}

export function SchedulePanelSmall(props: IData) {

    const [data, setData] = React.useState(props.data);
    const [days, setDays] = React.useState(data?.days);
    const [groupType, setGroupType] = React.useState(data?.groupType);
    const [currentDay, setCurrentDay] = React.useState(
        props.data?.days ? (props.data.days.find(i => i.isActive) ?? props.data.days[0]) : undefined)

    const handleChangeGroupType = async (
        event: React.MouseEvent<HTMLElement>,
        newType: GroupType | null,
    ) => {
        if (newType !== null) {
            setGroupType(newType);
            setData(null);
            setDays(undefined);
            var updated = await configService.updateGroupType(data?.id ?? "", newType);
            setData(updated);
            setDays(updated?.days);
            setCurrentDay(updated?.days[0])
        }
    };

    const onDelete = async (dayOfWeek: DayOfWeek, setting: SetpointSetting) => {
        var result = await configService.deleteTime(props.automation.id, dayOfWeek, setting);
        if (result && currentDay && days) {
            const updatedDay = { ...currentDay, settings: currentDay.settings.filter(i => i.id !== setting.id) };
            setData(result);
            setDays([...days.map(i => i.dayOfWeek !== dayOfWeek ? i : updatedDay)])
            setCurrentDay(updatedDay);
        }

        return result;
    }

    return (
        <Box sx={{ textAlign: 'left' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <ToggleButtonGroup
                    sx={{ flexWrap: 'wrap', marginLeft: '10px', marginTop: '10px', marginBottom: '10px' }}
                    exclusive
                    color="info"
                    value={groupType}
                    onChange={handleChangeGroupType}
                    size="small">
                    <ToggleButton value="None" aria-label="Default">
                        None
                    </ToggleButton>
                    <ToggleButton value="WeekendsWeekdays" aria-label="Default">
                        WeekendsWeekdays
                    </ToggleButton>
                    <ToggleButton value="All" aria-label="Default">
                        All
                    </ToggleButton>
                </ToggleButtonGroup>
                <Box>
                    <ToggleButtonGroup
                        sx={{ flexWrap: 'wrap', marginLeft: '10px', marginTop: '10px' }}
                        exclusive
                        color="info"
                        value={currentDay}
                        onChange={(ev, newValue) => setCurrentDay(newValue)}
                        size="small">
                        {days?.map(config => <ToggleButton
                            sx={{
                                fontSize: '15px',
                                border: 'none',
                                paddingX: '15px',
                            }}
                            key={config.dayOfWeek}
                            value={config}>
                            {groupType === GroupType.None ? config.dayOfWeek.toString().substring(0, 2).toUpperCase() : config.dayOfWeek}
                        </ToggleButton>)}
                    </ToggleButtonGroup>
                </Box>
            </Box>
            {days?.map((config, index) =>
                <div key={index} style={{ marginTop: '10px', marginBottom: '15px', display: config.dayOfWeek === currentDay?.dayOfWeek ? '' : 'none' }} >
                    <ScheduleDay isMobile={true} automation={props.automation} automationId={props.data?.id ?? ''} data={config} type={props.type} update={c => { setData(c); setDays(c.days); }} onDelete={onDelete} />
                </div>)}
        </Box>
    );
}

export function SchedulePanel(props: IData) {

    const [data, setData] = React.useState(props.data);
    const [days, setDays] = React.useState(data?.days);
    const [groupType, setGroupType] = React.useState(data?.groupType);
    const [currentDay, setCurrentDay] = React.useState(
        props.data?.days ? (props.data.days.find(i => i.isActive) ?? props.data.days[0]) : undefined)

    const handleChangeGroupType = async (
        event: React.MouseEvent<HTMLElement>,
        newType: GroupType | null,
    ) => {
        if (newType !== null) {
            setGroupType(newType);
            setData(null);
            setDays(undefined);
            var updated = await configService.updateGroupType(data?.id ?? "", newType);
            setData(updated);
            setDays(updated?.days);
            setCurrentDay(updated?.days[0])
        }
    };

    const onDelete = async (dayOfWeek: DayOfWeek, setting: SetpointSetting) => {
        var result = await configService.deleteTime(props.automation.id, dayOfWeek, setting);
        if (result && currentDay && days) {
            const updatedDay = { ...currentDay, settings: currentDay.settings.filter(i => i.id !== setting.id) };
            setData(result);
            setDays([...days.map(i => i.dayOfWeek !== dayOfWeek ? i : updatedDay)])
            setCurrentDay(updatedDay);
        }

        return result;
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '20px' }}>
                Change Grouping:<ToggleButtonGroup
                    sx={{ flexWrap: 'wrap', marginTop: '10px', marginBottom: '15px', marginLeft: '10px' }}
                    exclusive
                    color="info"
                    value={groupType}
                    onChange={handleChangeGroupType}
                    size="small">
                    <ToggleButton value="None">
                        None
                    </ToggleButton>
                    <ToggleButton value="WeekendsWeekdays">
                        WeekendsWeekdays
                    </ToggleButton>
                    <ToggleButton value="All">
                        All
                    </ToggleButton>
                </ToggleButtonGroup>
                <Box>
                    <ToggleButtonGroup
                        sx={{ flexWrap: 'wrap', marginTop: '10px' }}
                        exclusive
                        color="info"
                        value={currentDay}
                        onChange={(ev, newValue) => setCurrentDay(newValue)}
                        size="small">
                        {days?.map(config => <ToggleButton
                            sx={{
                                fontSize: '15px',
                                border: 'none',
                                paddingX: '20px',
                            }}
                            key={config.dayOfWeek}
                            value={config}>
                            {config.dayOfWeek}
                        </ToggleButton>)}
                    </ToggleButtonGroup>
                </Box>
            </Box>
            {days?.map((config, index) =>
                <div key={index} style={{ marginBottom: '15px', display: config.dayOfWeek === currentDay?.dayOfWeek ? '' : 'none' }} >
                    <ScheduleDay isMobile={false} automation={props.automation} automationId={props.data?.id ?? ''} data={config} type={props.type} update={c => { setData(c); setDays(c.days); }} onDelete={onDelete} />
                </div>)}
        </Box>
    );
}
