import { Box, Button, SxProps, Theme, Tooltip, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { isPropertyAccessExpression } from "typescript";

export class Point {
    public X: number;
    public Y: number;

    constructor(x: number, y: number) {
        this.X = x;
        this.Y = y;
    }
}

export class Line {
    public A: Point;
    public B: Point;

    constructor(a: Point, b: Point) {
        this.A = a;
        this.B = b;
    }

    public ToPolygonLine(thickness: number) {
        return `polygon(
            ${this.A.X}px ${this.A.Y}px,
            ${this.B.X + 1}px ${this.B.Y}px, 
            ${this.B.X + 1}px ${this.B.Y + thickness}px,
            ${this.A.X}px ${this.A.Y + thickness}px)`
    }

    public ToSvgPolygonLine(thickness: number) {
        return `${this.A.X},${this.A.Y} ${this.B.X + 1},${this.B.Y} ${this.B.X + 1},${this.B.Y + thickness} ${this.A.X},${this.A.Y + thickness}`
    }

    public ToPolygon(lineB: Line) {
        return `polygon(
            ${this.A.X}px ${this.A.Y}px,
            ${this.B.X + 1}px ${this.B.Y}px, 
            ${lineB.B.X + 1}px ${lineB.B.Y}px,
            ${lineB.A.X}px ${lineB.A.Y}px)`
    }

    public ToSvgPolygon(lineB: Line) {
        return `${this.A.X},${this.A.Y} ${this.B.X + 1},${this.B.Y} ${lineB.B.X + 1},${lineB.B.Y} ${lineB.A.X},${lineB.A.Y}`
    }
}

const AreaZIndex = 1;
const YRulerZIndex = 2;
const LineZIndex = 3;

interface LineGraphProps {
    width: number;
    height: number;
    coords: Point[];
    thickness: number;
    color: string;
    shouldConsolidate?: boolean;
}

function LineGraph(props: LineGraphProps) {
    const consolidated = !!props.shouldConsolidate ? ConsolidatePoints(props.coords) : props.coords;

    const getSvgPolygon = (a: ConsolidatedPoint | Point, i: number) => {
        if (i > consolidated.length - 2) {
            return null;
        }
        const b = consolidated[i + 1];

        // return <polygon points={new Line(a, b).ToSvgPolygonLine(props.thickness)} fill={props.color} />
        return <line key={i} x1={a.X} y1={a.Y} x2={b.X} y2={b.Y} stroke={props.color} strokeWidth={props.thickness} strokeLinecap="round" />
    }

    return <svg style={{ position: 'absolute', left: 0, top: 0, pointerEvents: 'none', zIndex: LineZIndex }} width={`${props.width}px`} height={`${props.height}px`}>
        {consolidated.map((item, i) => getSvgPolygon(item, i))}
    </svg>;
}

interface AreaGraphProps {
    width: number;
    height: number;
    coords: Point[];
    color: string;
    onClick?: (index: number) => void;
    shouldConsolidate?: boolean;
}

interface ConsolidatedPoint {
    X: number;
    Y: number;
    OriginalIndex: number;
}

function ConsolidatePoints(points: Point[]) {
    let consolidated = [] as ConsolidatedPoint[];
    points.forEach((point, index) => {
        if (index === 0 || index >= points.length - 2) {
            consolidated.push({ ...point, OriginalIndex: index });
            return;
        }

        if (point.Y === points[index - 1].Y && point.Y === points[index + 1].Y) {
            return;
        }

        consolidated.push({ ...point, OriginalIndex: index });
    });
    return consolidated;
}

function AreaGraph(props: AreaGraphProps) {
    const consolidated = !!props.shouldConsolidate ? ConsolidatePoints(props.coords) : props.coords;

    const getPolygon = (a: ConsolidatedPoint | Point, i: number) => {
        if (i > consolidated.length - 2) {
            return null;
        }
        const b = consolidated[i + 1];
        return <Button
            disabled={!props.onClick}
            key={i}
            onClick={() => { if (props.onClick) props.onClick(a instanceof ConsolidatePoints ? (a as ConsolidatedPoint).OriginalIndex : i); }} sx={{
                margin: 0,
                padding: 0,
                width: `${props.width}px`,
                height: `${props.height}px`,
                backgroundColor: props.color,
                position: 'absolute',
                border: 'none',
                borderRadius: '0px',
                top: 0,
                left: 0,
                clipPath: new Line(a, b).ToPolygon(new Line(new Point(a.X, props.height), new Point(b.X, props.height))),
                '&:hover': { backgroundColor: '#69a5b5' },
            }}>
        </Button>
    }

    const getSvgPolygon = (a: ConsolidatedPoint | Point, i: number) => {
        if (i > consolidated.length - 2) {
            return null;
        }
        const b = consolidated[i + 1];
        return <polygon key={i} points={new Line(a, b).ToSvgPolygon(new Line(new Point(a.X, props.height), new Point(b.X, props.height)))} fill={props.color} />
    }

    if (props.onClick) {
        return <React.Fragment>{consolidated.map((item, i) => getPolygon(item, i))}</React.Fragment>;
    }

    return <svg style={{ position: 'absolute', left: 0, top: 0, pointerEvents: 'none', zIndex: AreaZIndex }} width={`${props.width}px`} height={`${props.height}px`}>
        {consolidated.map((item, i) => getSvgPolygon(item, i))}
    </svg>;
}

function YAxisView(props: { yAxisRange: { min: number, max: number }, yScale: number, yAxis: YAxis, width: number, height: number }) {

    const calculateYAxisHeight = (y: number) => Math.max(0, props.height - ((Math.round(y) - props.yAxisRange.min) * props.yScale));

    const getYAxisLabel = (y: number, format: ((value: number) => JSX.Element | string) | undefined) => {
        return <div
            key={y}
            style={{
                zIndex: 10,
                position: 'absolute',
                left: '0px',
                lineHeight: '12px',
                bottom: `${((Math.round(y) - props.yAxisRange.min) * props.yScale) - 6}px`,
            }}>
            <div style={{
                fontSize: '12px',
            }}>{format ? format(y) : y}
            </div>
        </div>
    }

    const getYRuler = (axis: YAxis) => {
        return <svg width={props.width} height={props.height + 5} style={{ position: 'absolute', top: 0, left: 0, zIndex: YRulerZIndex }}>
            {axis.Points.map(p => <line key={p} x1={20} y1={calculateYAxisHeight(p)} x2={props.width} y2={calculateYAxisHeight(p)} stroke='gray' strokeWidth={1} strokeDasharray='4 1' />)}
        </svg>
    }

    return <React.Fragment>
        {props.yAxis ? <Box className='yaxis' sx={{
            width: '30px',
            height: props.height,
            position: 'absolute',
            left: 0,
            top: 0,
        }}>{props.yAxis.Points.map(i => getYAxisLabel(i, props.yAxis?.LabelFormat))}
        </Box> : null}
        {props.yAxis?.ShowRuler ? getYRuler(props.yAxis) : null}
    </React.Fragment>
}

export interface Series {
    data: Point[],
    area?: boolean,
    color: string,
    label?: boolean,
    thickness?: number,
    labelFormat?: (value: number) => JSX.Element,
}

export interface XAxis {
    points: string[],
    maxLabelWidth?: number,
}

export interface YAxis {
    Points: number[],
    LabelFormat?: (value: number) => JSX.Element | string,
    ShowRuler?: boolean,
}

export interface GreyGraphProps {
    sx?: SxProps<Theme>,
    series: Series[],
    xAxis: XAxis,
    yAxis?: YAxis,
    width: number,
    height: number,
    yAxisRange: { min: number, max: number },
    onClick?: (index: number) => void,
    shouldConsolidate?: boolean,
}

export function GetRange(start: number, end: number, step: number) {
    var c = start;
    var result = [start];
    while (c < end) {
        c += step;
        result = result.concat([c])
    }

    return result;
}

export function GreyGraph(props: GreyGraphProps) {
    const xOffset = 40 / 2;
    const xScale = (props.width - xOffset) / props.xAxis.points.length;
    const yRange = props.yAxisRange.max - props.yAxisRange.min;
    const yScale = props.height / yRange;
    const coords = props.series.map(series => series.data.map((point, index) => new Point(
        Math.round((point.X * xScale) + xOffset),
        Math.round(Math.max(0, props.height - ((Math.round(point.Y) - props.yAxisRange.min) * yScale))))));

    const maxLabels = Math.max(Math.floor(props.width / (props.xAxis.maxLabelWidth ?? 50)), 1);
    let step = Math.ceil(props.xAxis.points.length / maxLabels);
    step = (!props.xAxis.maxLabelWidth || step <= 1) ? 1 : step;
    const xAxis = props.xAxis.points.map((i, index) => index % step == 0 ? i : '');

    const getXAxisLabel = (label: string, index: number) => {
        return <div
            key={index}
            style={{
                fontSize: '12px',
                zIndex: 10,
                position: 'absolute',
                left: `${index * xScale}px`,
                bottom: '5px',
                whiteSpace: 'nowrap',
            }}>{label}
        </div>
    }


    const getPointLabel = (series: Series, index: number) => {
        if (index % step != 0) {
            return <div key={index} />
        }
        const defaultFormatter = (v: number) => <Typography>{v.toString()}</Typography>;
        const formatter = series.labelFormat ?? defaultFormatter;
        return <div
            key={index}
            style={{
                position: 'absolute',
                left: `${coords[props.series.indexOf(series)][index].X - 7}px`,
                top: `${coords[props.series.indexOf(series)][index].Y - 20}px`,
                fontSize: '12px',
                zIndex: 10,
            }}>{formatter(series.data[index].Y)}
        </div>
    }

    const getGraph = (series: Series, seriesIndex: number) => {
        if (series.area) {
            return <AreaGraph
                key={seriesIndex}
                width={props.width}
                height={props.height}
                coords={coords[seriesIndex]}
                onClick={props.onClick}
                color={series.color}
                shouldConsolidate={props.shouldConsolidate} />
        }
        return <LineGraph
            key={seriesIndex}
            width={props.width}
            height={props.height}
            coords={coords[seriesIndex]}
            thickness={series.thickness ?? 5}
            color={series.color}
            shouldConsolidate={props.shouldConsolidate} />
    }

    return <Box sx={props.sx}>
        <Box sx={{
            position: 'relative',
            width: props.width,
            height: props.height + 5,
        }}>
            <div>
                {props.series.filter(i => i.label).map((series, seriesIndex) => series.data.map((i, index) => getPointLabel(series, index)))}
                {props.series.map((series, seriesIndex) => getGraph(series, seriesIndex))}
            </div>
            {props.yAxis ? <YAxisView yAxis={props.yAxis} width={props.width} height={props.height} yAxisRange={props.yAxisRange} yScale={yScale} /> : null}
        </Box>
        <Box sx={{
            overflow: 'hidden',
            width: props.width,
            height: '30px',
            position: 'relative',
        }}>{xAxis.map((item, i) => getXAxisLabel(item, i))}
        </Box>

    </Box>
}