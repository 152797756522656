import { Box, CardContent, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useState } from "react";
import { ITemperatureAutomationState } from "../../models/Models";
import { useEventHub } from "../../services/EventHub";
import * as Grey from "../StyledComponents";
import { PanelProps } from "./PanelProps";

function GetColorTemp(temp: number | null | undefined) {
    if (!temp) {
        return 'red';
    }

    if (temp > 77) {
        return 'orange';
    }
    if (temp > 70) {
        return '#538f51';
    }

    return '#03cffc';
}

function TemperaturePanelSmall(props: PanelProps) {
    const [data, setData] = useState(props.data as ITemperatureAutomationState);

    useEventHub<ITemperatureAutomationState>(data.id, setData);

    return <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Grey.CardTitleButton onClick={props.handleOpen}>
            <Typography className='smallCardTitle' color='secondary' fontSize='14px'>
                {data?.title}
            </Typography>
        </Grey.CardTitleButton>
        <Box sx={{ marginY: '3px', marginLeft: '15px', marginRight: '10px', textAlign: 'left' }}>
            <Box sx={{ display: 'block', marginTop: '20px' }}>
                {data.temperatures.map(temp => <Box key={temp.name} sx={{ display: 'inline' }}>
                    <Typography sx={{ float: 'left', color: '#A9A9A9' }}>{temp.name}: &nbsp;</Typography><Typography color={GetColorTemp(temp.temperature)}>{!temp.temperature ? 'unknown' : (temp.temperature + '°F')}</Typography>
                </Box>)}
            </Box>
        </Box>
    </Box>
}

function TemperaturePanel(props: PanelProps) {

    const [data, setData] = useState(props.data as ITemperatureAutomationState);
    const theme = useTheme();

    useEventHub<ITemperatureAutomationState>(data.id, setData);

    return (
        <Grey.AutomationCard className={`card card-${theme.name}`} variant="outlined">
            <CardContent>
                <Grey.AutomationTitle>
                    {data.title}
                </Grey.AutomationTitle>
                <div style={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: 14, textAlign: 'left' }} color="text.secondary" gutterBottom>
                        {data.status}
                    </Typography>
                    <Box>
                        {data.temperatures.map(temp => <Box key={temp.name} sx={{ display: 'inline' }}>
                            <Typography sx={{ float: 'left', color: '#A9A9A9' }}>{temp.name}: &nbsp;</Typography><Typography color={GetColorTemp(temp.temperature)}>{!temp.temperature ? 'unknown' : (temp.temperature + '°F')}</Typography>
                        </Box>)}
                    </Box>
                </div>
            </CardContent>
        </Grey.AutomationCard>
    );
}
export { TemperaturePanel, TemperaturePanelSmall };

