import { useEffect, useRef, useState } from "react";
import { IAutomationModel } from "../models/Models";
import { useEventHub } from "./EventHub";

export const useInterval = (callback: () => void, delay: number) => {

    const savedCallback = useRef<() => void>();

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);


    useEffect(() => {
        function tick() {
            // @ts-ignore: Object is possibly 'null'.
            savedCallback.current();
        }
        if (delay !== null) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function IsAndroidApp() {
    return navigator.userAgent.includes('Pixel 3 Build');
}

export function IsTouch() {
    return matchMedia('(hover: none)').matches;
}

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export function UseWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export function UseAutomations(automations: IAutomationModel[]) {
    const [a, setA] = useState(automations);

    useEventHub("*", (updated: IAutomationModel) => setA(a.map(i => i.id == updated.id ? updated : i)))

    return a;
}
