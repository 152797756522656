import { Box, CardContent, Stack, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useState } from "react";
import { ICatFeederAutomationModel } from "../../models/Models";
import { useEventHub } from "../../services/EventHub";
import * as Grey from "../StyledComponents";
import { SettingsMenuButton } from "../configs/SettingsMenuButton";
import { PanelProps } from "./PanelProps";
import { WarningAmber } from "@mui/icons-material";
import React from "react";
import dayjs from "dayjs";

export function CatFeederPanelSmall(props: PanelProps) {
    const [data, setData] = useState(props.data as ICatFeederAutomationModel);

    useEventHub<ICatFeederAutomationModel>(data.id, setData);

    return <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Grey.CardTitleButton onClick={props.handleOpen}>
            <Typography className='smallCardTitle' color='secondary' fontSize='14px'>
                {data?.title}
            </Typography>
        </Grey.CardTitleButton>
        <Box sx={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px', marginBottom: '10px', textAlign: 'left' }}>
            <Typography>Total Food Drops: {data.totalFoodDrops}</Typography>
        </Box>
    </Box>
}

export function CatFeederPanel(props: PanelProps) {

    const [data, setData] = useState(props.data as ICatFeederAutomationModel);
    const theme = useTheme();

    useEventHub<ICatFeederAutomationModel>(data.id, setData);

    return (
        <Grey.AutomationCard className={`card card-${theme.name}`} variant="outlined">
            <CardContent>
                <Grey.AutomationTitle>
                    {data.title}
                    <SettingsMenuButton automation={data} />
                </Grey.AutomationTitle>
                <div style={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: 14, textAlign: 'left' }} color="text.secondary" gutterBottom>
                        {data.status}
                    </Typography>
                    <Box sx={{ marginTop: '0px', marginLeft: '0px' }}>
                        {data.isAvailable ?
                            <React.Fragment>
                                <Typography>Total Food Drops: {data.totalFoodDrops}</Typography>
                                {data.isJammed ?
                                    <Stack direction='row' sx={{ marginTop: '10px' }}>
                                        <WarningAmber color='error' />
                                        <Typography sx={{ marginTop: '2px', marginLeft: '10px' }}>Jam Detected at {dayjs(data.jamTime).format('h:mm a')} on {dayjs(data.jamTime).format('dddd')}!</Typography>
                                    </Stack>
                                    : null}
                                {data.isEmpty ?
                                    <Stack direction='row' sx={{ marginTop: '10px' }}>
                                        <WarningAmber color='warning' />
                                        <Typography sx={{ marginTop: '2px', marginLeft: '10px' }}>Empty!</Typography>
                                    </Stack>
                                    : null}
                            </React.Fragment>
                            : <Stack direction='row' sx={{ marginTop: '10px' }}>
                                <WarningAmber color='error' />
                                <Typography sx={{ marginTop: '2px', marginLeft: '10px' }}>Device is offline!</Typography>
                            </Stack>}

                    </Box>
                </div>
            </CardContent>
        </Grey.AutomationCard>
    );
}

