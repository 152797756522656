import { LockOpenTwoTone, LockTwoTone, WarningAmber } from "@mui/icons-material";
import { Backdrop, Box, CardContent, CircularProgress, Stack, ToggleButtonGroup, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import React, { useState } from "react";
import { IAutomationModel, ILockAutomationModel, LockStatus } from "../../models/Models";
import { appService } from "../../services/AppService";
import { useEventHub } from "../../services/EventHub";
import { LockSwitch } from "../controls/LockSwitch";
import * as Grey from "../StyledComponents";
import { TemporarySchedule } from "../controls/TemporarySchedule";
import { PanelProps } from "./PanelProps";

function LockIcon(lockStatus: LockStatus) {
    if (lockStatus === LockStatus.Unlocked) {
        return <LockOpenTwoTone color='success' />;
    }
    else if (lockStatus === LockStatus.Locked) {
        return <LockTwoTone color='disabled' />;
    }

    return <WarningAmber color='error' />;
}

function BatteryStatus(level: number, topMargin?: string) {
    const color = level > 60 ? 'success' : (level > 30 ? 'warning' : 'error');
    return <Box sx={{ position: 'relative', display: 'inline-flex', float: 'right', marginTop: topMargin ?? '0' }}>
        <CircularProgress variant="determinate" value={level} color={color} />
        <Box
            sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Typography
                variant="caption"
                component="div"
                color='text.secondary'
            >{`${level}%`}
            </Typography>
        </Box>
    </Box>
}

function LockPanelSmall(props: PanelProps) {
    const [data, setData] = useState(props.data as ILockAutomationModel);
    const [lockStatus, setLockStatus] = useState(data.lockStatus);
    const [battery, setBattery] = useState(data.batteryLevel);

    const [isLoading, setIsLoading] = React.useState(false);

    const handleLockChange = async (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
        setIsLoading(true);
        if (checked) {
            setLockStatus(LockStatus.Locked);
            var updated = await appService.Locks.lock(data.id);
            updateAll(updated);
        }
        else {
            setLockStatus(LockStatus.Unlocked);
            var updated = await appService.Locks.unlock(data.id);
            updateAll(updated);
        }
        setIsLoading(false);
    };

    const updateAll = (updated: IAutomationModel | null) => {
        var model = updated as ILockAutomationModel;
        if (model) {
            setData(model);
            setBattery(model.batteryLevel);
            setLockStatus(model.lockStatus);
        }
    }

    useEventHub(data.id, updateAll);

    return <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Grey.CardTitleButton onClick={props.handleOpen}>
            <Grey.AutomationTitleSmall className='smallCardTitle' color='primary' fontSize='14px'>
                {data?.title}
            </Grey.AutomationTitleSmall>
            <Stack direction='row' sx={{ position: 'absolute', right: '10px' }}>
                {LockIcon(lockStatus)}
            </Stack>
        </Grey.CardTitleButton>
        <Box sx={{ marginY: '3px', marginLeft: '0px', marginRight: '10px', textAlign: 'left' }}>
            <LockSwitch sx={{ m: 1 }} onChange={handleLockChange} disabled={lockStatus === LockStatus.Unknown} checked={lockStatus === LockStatus.Locked} />
            {/* {data.status} */}
            {BatteryStatus(battery ?? 0, '5px')}
        </Box>
        <Backdrop sx={{ position: 'absolute' }} open={isLoading}>
            <CircularProgress color="inherit" />
        </Backdrop>
    </Box>
}

function LockPanel(props: PanelProps) {
    const [data, setData] = useState(props.data as ILockAutomationModel);
    const [lockStatus, setLockStatus] = useState(data.lockStatus);
    const [battery, setBattery] = useState(data.batteryLevel);
    const theme = useTheme();

    const handleLockChange = async (
        event: React.MouseEvent<HTMLElement>,
        newValue: LockStatus | null,
    ) => {
        if (newValue === LockStatus.Locked) {
            setLockStatus(newValue);
            var updated = await appService.Locks.lock(data.id);
            updateAll(updated);
        }
        else if (newValue === LockStatus.Unlocked) {
            setLockStatus(newValue);
            var updated = await appService.Locks.unlock(data.id);
            updateAll(updated);
        }
    };

    const updateAll = (updated: IAutomationModel | null) => {
        var model = updated as ILockAutomationModel;
        if (model) {
            setData(model);
        }
    }

    useEventHub(data.id, updateAll);

    const setTemporarySchedule = async (time: number | null) => {
        if (time) {
            var updated = await appService.Locks.unlockUntil(data.id, time)
            updateAll(updated);
        }
    }

    return (<Grey.AutomationCard key={data.id} className={`card card-${theme.name}`} variant="outlined">
        <CardContent>
            <Grey.AutomationTitle>
                {data.title}
                <Box sx={{ float: 'right' }}>{LockIcon(lockStatus)}</Box>
            </Grey.AutomationTitle>
            <div style={{ textAlign: "left" }}>
                <Typography sx={{ fontSize: 14, textAlign: 'left' }} color="text.secondary" gutterBottom>
                    {data.status}
                </Typography>
                <Box sx={{ flex: 'wrap', marginTop: '20px' }}>
                    {lockStatus === LockStatus.Unknown ?
                        <Typography sx={{ verticalAlign: 'top' }}>Could not get lock status!</Typography>
                        : (<ToggleButtonGroup
                            sx={{ flexWrap: 'wrap' }}
                            exclusive
                            color="info"
                            value={lockStatus}
                            onChange={handleLockChange}
                            size="small">
                            <Grey.CustomToggleButton sx={{ width: '100px' }} value={LockStatus.Unlocked}>
                                Unlock
                            </Grey.CustomToggleButton>
                            <Grey.CustomToggleButton sx={{ width: '100px' }} value={LockStatus.Locked}>
                                Lock
                            </Grey.CustomToggleButton>
                        </ToggleButtonGroup>)}
                    {BatteryStatus(battery ?? 0)}
                </Box>
                {props.isModal
                    ? <Box sx={{ marginTop: '25px', paddingY: '5px', borderTop: '1px solid #000000', borderBottom: '1px solid #000000' }}>
                        <TemporarySchedule automation={data} onSave={setTemporarySchedule} message='Unlock' okText='Unlock' formOnly={true} buttonContent='Temporary Unlock' />
                    </Box>
                    : <TemporarySchedule automation={data} onSave={setTemporarySchedule} sx={{ marginTop: '10px' }} message='Unlock' okText='Unlock' buttonContent='Temporary Unlock' />}
                {data.doorIsOpen ? <Typography marginTop='10px'>{data.title} is opened!</Typography> : null}

            </div>
        </CardContent>
    </Grey.AutomationCard>
    );
}
export { LockPanel, LockPanelSmall };

