import { LocalFloristTwoTone, PoolTwoTone, WarningTwoTone } from "@mui/icons-material";
import { Box, CardContent, Slider, Stack, SxProps, Theme, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { IPlantAutomationState, IPlantOverviewAutomationState, IPlantState } from "../../models/Models";
import { useEventHub } from "../../services/EventHub";
import { GreyGraph, Point, Series } from "../controls/GreyGraph";
import * as Grey from "../StyledComponents";
import { PanelProps } from "./PanelProps";
import { SettingsMenuButton } from "../configs/SettingsMenuButton";

function GetSliderColor(data: IPlantState) {
    if (data.waterLevel < data.waterMinimum) {
        return '#eb9b34';
    }
    if (data.waterLevel > data.waterMaximum) {
        return '#5199e0';
    }
    return '#4bb88c';
}

function GetPlantIcon(p: IPlantState) {
    if (!p.isAvailable) {
        return <WarningTwoTone color='error' />
    }
    if (p.waterLevel > p.waterMaximum) {
        return <PoolTwoTone htmlColor={GetSliderColor(p)} />
    }
    if (p.waterLevel < p.waterMinimum) {
        return <img src='../cactus.png' width={24} height={24} />
    }

    if (p.name == 'Roses') {
        return <img src='../rose.png' width={24} height={24} />
    }
    else if (p.name == 'Daisies') {
        return <img src='../daisy.png' width={32} height={32} />
    }
    else if (p.name == 'Flower Bed') {
        return <img src='../flowers.png' width={24} height={24} />
    }
    else if (p.name == 'Cat Water Bowl') {
        return <img src='../kitty.png' width={24} height={24} />
    }
    return <LocalFloristTwoTone htmlColor={GetSliderColor(p)} />
}

function PlantOverviewPanelSmall(props: PanelProps) {
    const [data, setData] = useState(props.data as IPlantOverviewAutomationState);

    useEventHub<IPlantOverviewAutomationState>(data.id, setData);

    return <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Grey.CardTitleButton onClick={props.handleOpen}>
            <Typography className='smallCardTitle' color='secondary' fontSize='14px'>
                {data?.title}
            </Typography>
        </Grey.CardTitleButton>
        <Box sx={{ marginY: '3px', marginLeft: '15px', marginRight: '10px', textAlign: 'left' }}>
            <Box sx={{ display: 'block', marginTop: '20px' }}>
                {data.plants?.map(plant => <Box key={plant.entityId} sx={{ display: 'inline' }}>
                    <Typography sx={{ float: 'left', color: '#A9A9A9' }}>{plant.name}: &nbsp;</Typography>
                    {GetPlantIcon(plant)}
                </Box>)}
            </Box>
        </Box>
    </Box>
}

function Plant(props: { automationId: string, data: IPlantState, sliderOnly: boolean, sx?: SxProps<Theme> }) {

    const [data, setData] = useState(props.data);
    const [waterRange, setWaterRange] = useState([data.waterMinimum, data.waterMaximum]);
    const [icon, setIcon] = useState(GetPlantIcon(data))
    const theme = useTheme();

    const updateAll = (updated: IPlantState) => {
        setData(updated);
        setWaterRange([updated.waterMinimum, updated.waterMaximum]);
        setIcon(GetPlantIcon(updated))
    }

    useEffect(() => {
        updateAll(props.data);
    }, [props.data])

    const marks = [
        {
            value: data.waterMinimum,
            label: data.waterMinimum + '%',
        },
        {
            value: data.waterMaximum,
            label: data.waterMaximum + '%',
        },
        {
            value: data.waterLevel + 2,
            label: data.waterLevel + '%',
        },
    ];

    const sliderStyle = {
        marginRight: '7px',
        marginTop: '-2px',
        color: GetSliderColor(data),
        '& .Mui-disabled': { color: GetSliderColor(data) },
        '& .MuiSlider-rail': { color: '#80635b', opacity: .5 },
        '& .MuiSlider-track': { color: GetSliderColor(data) },
        '& .MuiSlider-mark[data-index="2"]': { width: 8, height: 8, borderRadius: '10px', backgroundColor: theme.palette.text.secondary },
        '& .MuiSlider-markLabel[data-index="2"]': { color: GetSliderColor(data), background: theme.card.background, borderRadius: '20px', paddingX: '5px' },
        '& .MuiSlider-thumb': {
            width: 8,
            height: 8,
            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
            '&:before': {
                boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
            },
            '&:hover, &.Mui-focusVisible': {
                boxShadow: `0px 0px 0px 8px ${'rgb(255 255 255 / 16%)'
                    }`,
            }
        }
    };

    if (props.sliderOnly) {
        return <Box sx={{ ...props.sx }}><Slider
            disabled
            color="primary"
            sx={sliderStyle}
            marks={marks}
            min={0}
            max={100}
            getAriaLabel={() => 'Temperature range'}
            value={waterRange}
            step={1}
            valueLabelDisplay="auto"
        /></Box>
    }

    return <Stack direction='row'>
        <Typography sx={{ float: 'left', color: '#A9A9A9', marginRight: '8px' }}>{data.name}</Typography>
        <Slider
            disabled
            color="primary"
            sx={{ ...sliderStyle, marginX: '15px' }}
            marks={[{
                value: data.waterLevel + 2,
                label: data.waterLevel + '%',
            }]}
            min={0}
            max={100}
            getAriaLabel={() => 'Temperature range'}
            value={waterRange}
            step={1}
            valueLabelDisplay="auto"
        />
        {icon}
    </Stack>
}

function PlantOverviewPanel(props: PanelProps) {

    const [data, setData] = useState(props.data as IPlantOverviewAutomationState);
    const theme = useTheme();

    useEventHub<IPlantOverviewAutomationState>(data.id, setData);

    return (
        <Grey.AutomationCard className={`card card-${theme.name}`} variant="outlined">
            <CardContent>
                <Grey.AutomationTitle>
                    {data.title}
                </Grey.AutomationTitle>
                <div style={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: 14, textAlign: 'left' }} color="text.secondary" gutterBottom>
                        {data.status}
                    </Typography>
                    <Box>
                        {data.plants?.map(plant => <Plant key={plant.entityId} automationId='garbageid' data={plant} sliderOnly={false} />)}
                    </Box>
                </div>
            </CardContent>
        </Grey.AutomationCard>
    );
}

function PlantPanelSmall(props: PanelProps) {

    const [data, setData] = useState(props.data as IPlantAutomationState);
    const [history, setHistory] = useState(data.plant.history);
    const theme = useTheme();
    const [icon, setIcon] = useState(GetPlantIcon(data.plant))

    const updateAll = (updated: IPlantAutomationState | null) => {
        if (updated) {
            setData(updated);
            setHistory(updated.plant.history);
            setIcon(GetPlantIcon(updated.plant))
        }
    }

    useEventHub<IPlantAutomationState>(data.id, updateAll);

    function GetLabelColor(level: number) {
        if (level < data.plant.waterMinimum) {
            return '#eb9b34';
        }
        if (level > data.plant.waterMaximum) {
            return '#5199e0';
        }
        return theme.palette.text.primary;
    }

    const series = [
        { data: history.map((i, index) => new Point(index, Math.round(i.waterLevel))), color: '#a2edfc', area: true, label: false },
        { data: history.map((i, index) => new Point(index, Math.round(data.plant.waterMinimum))), color: '#eba360', area: true, label: false },
        {
            data: history.map((i, index) => new Point(index, Math.round(i.waterLevel))),
            color: '#41b057',
            area: false,
            label: true,
            thickness: 5,
            labelFormat: (value: number) =>
                <Typography fontSize={13} fontFamily="Comic Sans MS, Comic Sans" sx={{ opacity: .8 }}>
                    {Math.round(value)}%
                </Typography>
        },
    ] as Series[];

    return (<Box sx={{ flexGrow: 1, position: 'relative', textAlign: "left" }}>
        <Grey.CardTitleButton onClick={props.handleOpen}>
            <Grey.AutomationTitleSmall className='smallCardTitle' color='secondary' fontSize='14px'>
                {data?.title}
            </Grey.AutomationTitleSmall>
            <Box sx={{ position: 'absolute', right: '10px', top: '4px' }}>{icon}</Box>
        </Grey.CardTitleButton>
        <Typography sx={{ fontSize: 14, textAlign: 'left' }} color="text.secondary" gutterBottom>
            {data.status}
        </Typography>
        <Box sx={{ marginX: '10px' }}>
            {history.length < 3 ? <Box sx={{
                height: 120, display: 'flex', alignItems: 'center', justifyContent: 'center',
                background: 'repeating-linear-gradient(-45deg,#a69b9022,#a69b9022 10px,#46529800 10px,#46529800 20px)'
            }}>
                <Typography sx={{ opacity: .6 }}>No History</Typography>
            </Box> :
                <GreyGraph
                    series={series}
                    xAxis={{ maxLabelWidth: 50, points: history.map(i => dayjs(i.timestamp).format('h:mma').slice(0, -1)) }}
                    width={310}
                    height={80}
                    yAxisRange={{ min: 0, max: Math.max(...history.map(i => i.waterLevel)) + 15 }} />
            }
            <Plant key={data.plant.entityId} automationId={data.id} data={data.plant} sliderOnly={true} sx={{ marginX: '15px' }} />
        </Box>
    </Box>
    );
}

function PlantPanel(props: PanelProps) {

    const [data, setData] = useState(props.data as IPlantAutomationState);
    const [history, setHistory] = useState(data.plant.history);
    const theme = useTheme();
    const [icon, setIcon] = useState(GetPlantIcon(data.plant))

    const updateAll = (updated: IPlantAutomationState | null) => {
        if (updated) {
            setData(updated);
            setHistory(updated.plant.history);
            setIcon(GetPlantIcon(updated.plant))
        }
    }

    useEventHub<IPlantAutomationState>(data.id, updateAll);

    const series = [
        { data: history.map((i, index) => new Point(index, Math.round(i.waterLevel))), color: '#a2edfc', area: true, label: false },
        { data: history.map((i, index) => new Point(index, Math.round(data.plant.waterMinimum))), color: '#eba360', area: true, label: false },
        {
            data: history.map((i, index) => new Point(index, Math.round(i.waterLevel))),
            color: '#41b057',
            area: false,
            label: true,
            thickness: 5,
            labelFormat: (value: number) =>
                <Typography fontSize={13} sx={{ opacity: .8 }}>
                    {Math.round(value)}%
                </Typography>
        },
    ] as Series[];

    return (
        <Grey.AutomationCard className={`card card-${theme.name}`} variant="outlined" sx={{ position: 'relative', fontFamily: 'Comic Sans MS, Comic Sans' }} >
            <CardContent>
                <Grey.AutomationTitle>
                    {data.title}
                    <SettingsMenuButton automation={data} />
                </Grey.AutomationTitle>
                <div style={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: 14, textAlign: 'left' }} color="text.secondary" gutterBottom>
                        {data.status}
                    </Typography>
                    <Box sx={{ marginBottom: '10px' }}>
                        {history.length < 2 ? <Box sx={{
                            height: 120, display: 'flex', alignItems: 'center', justifyContent: 'center',
                            background: 'repeating-linear-gradient(-45deg,#a69b9022,#a69b9022 10px,#46529800 10px,#46529800 20px)'
                        }}>
                            <Typography sx={{ opacity: .6 }}>No History</Typography>
                        </Box> :
                            <GreyGraph
                                series={series}
                                xAxis={{ points: history.map(i => dayjs(i.timestamp).format('ddd ha')), maxLabelWidth: 50 }}
                                width={310}
                                height={120}
                                yAxisRange={{ min: 0, max: Math.max(...history.map(i => i.waterLevel)) + 15 }}
                                shouldConsolidate={true} />
                        }
                    </Box>
                    {data.plant.isAvailable
                        ? <Plant key={data.plant.entityId} automationId={data.id} data={data.plant} sliderOnly={true} sx={{ marginX: '15px' }} />
                        : <Typography>Could not connect to sensor.</Typography>}
                    <Typography>{data.plant.status}</Typography>
                </div>
            </CardContent>
        </Grey.AutomationCard>
    );
}
export { PlantOverviewPanel, PlantOverviewPanelSmall, PlantPanel, PlantPanelSmall };

