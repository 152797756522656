import { BarChart, SettingsTwoTone } from "@mui/icons-material";
import { Box, Menu, MenuItem, Modal, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import React from "react";
import { useNavigate } from "react-router-dom";
import { AutomationType, ESModel, IAutomationModel, IContactSensorsAutomationModel, ScheduleConfigModel } from "../../models/Models";
import { appService } from "../../services/AppService";
import { configService } from "../../services/ConfigService";
import { UseWindowDimensions } from "../../services/Utils";
import { IrCommandsModal } from "../controls/IrCommandsModal";
import * as Grey from "../StyledComponents";
import { ChartPanel } from "./ChartPanel";
import { CreateNewSetpoint } from "./ScheduleDay";
import { SchedulePanel } from "./SchedulePanel";

export function SettingsMenuButton(props: { automation: IAutomationModel }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const windowSize = UseWindowDimensions();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorEl);
  const handleSettingsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const [automation, setAutomation] = React.useState(props.automation);
  const [irSensorId, setIrSensorId] = React.useState("");
  const [scheduleOpen, setScheduleOpen] = React.useState(false);
  const [scheduleConfig, setScheduleConfig] = React.useState<ScheduleConfigModel | null>(null);

  const supportsSchedule = CreateNewSetpoint(automation.type).$type !== 'Unknown';
  const handleScheduleOpen = async (id: string) => {
    try {
      if (windowSize.width < 700) {
        navigate(`/config/${props.automation.id}`);
        return;
      }

      const s = await configService.getSchedule(id);
      setScheduleConfig(s);
      setScheduleOpen(true);
    }
    catch {
    }
  }

  const [irCommandsOpen, setIrCommandsOpen] = React.useState(false);
  const handleIrCommandsOpen = async (sensorId: string) => {
    try {
      if (windowSize.width < 700) {
        navigate(`/irconfig/${props.automation.id}/${sensorId}`);
        return;
      }

      const s = await appService.Automations.getAutomation(props.automation.id);
      if (s) {
        setAutomation(s);
        setIrSensorId(sensorId);
        setIrCommandsOpen(true);
      }
    }
    catch {
    }
  }

  const [historyOpen, setHistoryOpen] = React.useState(false);
  const [statsData, setStatsData] = React.useState<ESModel[] | null>(null);
  const [automationType, setAutomationType] = React.useState(AutomationType.Climate);
  const handleHistoryOpen = async () => {
    try {
      if (props.automation.type === AutomationType.ContactSensors) {
        const contactAutomation = automation as IContactSensorsAutomationModel;
        const s = await appService.Automations.getHistory(props.automation.id, contactAutomation.sensors[0].entityId);
        if (s) {
          setAutomationType(AutomationType.ContactSensors);
          setStatsData(s);
          setHistoryOpen(true);
        }
      }
      else {
        const s = await appService.Automations.getHistory(props.automation.id);
        if (s) {
          setAutomationType(props.automation.type);
          setStatsData(s);
          setHistoryOpen(true);
        }
      }
    }
    catch {
    }
  }

  const styleSmall = {
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingTop: 4,
    paddingLeft: 4,
    paddingRight: 4,
  };
  const styleLarge = {
    position: 'absolute' as 'absolute',
    top: '40%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    paddingTop: 4,
    paddingLeft: 4,
    paddingRight: 4,
  };

  const irRemoteName = (sensorId: string) => {
    if (!props.automation.irSensors || Object.keys(props.automation.irSensors).length <= 1) {
      return "IR Commands";
    }
    return `IR Commands (${props.automation.irSensors[sensorId]})`;
  }
  return (<div style={{ float: 'right' }}>
    {supportsSchedule ?
      <Grey.CustomIconButton sx={{ padding: 0 }} onClick={handleSettingsClick}><SettingsTwoTone /></Grey.CustomIconButton>
      :
      <Grey.CustomIconButton sx={{ padding: 0, border: '1px solid' }} onClick={handleHistoryOpen}><BarChart /></Grey.CustomIconButton>}
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={() => handleMenuClose()}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={() => { handleMenuClose(); handleScheduleOpen(props.automation.id); }}>Schedule</MenuItem>
      {props.automation.irSensors ? Object.keys(props.automation.irSensors).map(sensorId => <MenuItem key={sensorId} onClick={() => { handleMenuClose(); handleIrCommandsOpen(sensorId); }}>{irRemoteName(sensorId)}</MenuItem>) : null}
      <MenuItem onClick={() => { handleMenuClose(); handleHistoryOpen(); }}>History</MenuItem>
    </Menu>
    <Modal
      open={scheduleOpen}
      onClose={() => setScheduleOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box>
        <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
          <Box sx={styleSmall}>
            <Typography color={theme.palette.text.primary} id="modal-modal-title" variant="h6" component="h2">
              {scheduleConfig?.title}
            </Typography>
            <SchedulePanel automation={props.automation} data={scheduleConfig} type={props.automation.type} />
          </Box>
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }}>
          <Box sx={styleLarge}>
            <Typography color={theme.palette.text.primary} id="modal-modal-title" variant="h6" component="h2">
              {scheduleConfig?.title}
            </Typography>
            <SchedulePanel automation={props.automation} data={scheduleConfig} type={props.automation.type} />
            <Grey.CustomButton onClick={() => setScheduleOpen(false)} variant="contained" sx={{ float: 'right', marginBottom: '15px' }}>Done</Grey.CustomButton>
          </Box>
        </Box>
      </Box>
    </Modal>
    <IrCommandsModal isOpen={irCommandsOpen} onClose={() => setIrCommandsOpen(false)} automation={automation} irSensorId={irSensorId} />
    <Modal
      open={historyOpen}
      onClose={() => setHistoryOpen(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={styleLarge}>
        <Typography color={theme.palette.text.primary} id="modal-modal-title" variant="h6" component="h2">
          {scheduleConfig?.title}
        </Typography>
        <ChartPanel automation={automation} data={statsData ?? []} />
      </Box>
    </Modal>
  </div>);
}

