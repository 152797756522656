import { Battery5BarTwoTone, Blinds, DoorBackSharp, DoorFrontTwoTone } from "@mui/icons-material";
import { Box, CardContent, CircularProgress, Stack, Tooltip, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useState } from "react";
import { IBatteryAutomationState, IBatteryState } from "../../models/Models";
import { useEventHub } from "../../services/EventHub";
import * as Grey from "../StyledComponents";
import { PanelProps } from "./PanelProps";

function GetColorTemp(percent: number | null | undefined) {
    if (!percent) {
        return 'red';
    }

    if (percent > 60) {
        return '#068a13';
    }
    if (percent > 35) {
        return '#f0ca0c';
    }

    return '#f0890c';
}

function BatteryStatus(battery: IBatteryState, index: number) {
    const icon = () => {
        const margin = '8px';

        if (battery.name.toLowerCase().includes('deadbolt')) {
            return <DoorBackSharp sx={{ marginLeft: margin }} />
        }
        if (battery.name.toLowerCase().includes('blind')) {
            return <Blinds sx={{ marginLeft: margin }} />
        }
        if (battery.name.toLowerCase().includes('door')) {
            return <DoorFrontTwoTone sx={{ marginLeft: margin }} />
        }
        return <Battery5BarTwoTone sx={{ marginLeft: margin }} />;
    };
    const color = GetColorTemp(battery.percent);
    return <Tooltip key={index} title={battery.name}><Stack direction='column' sx={{ position: 'relative', display: 'inline-flex', marginRight: '5px' }}>
        <Box>
            <CircularProgress variant="determinate" value={battery.percent ?? 0} sx={{ color: color }} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography
                    sx={{ position: 'absolute', top: '10px' }}
                    variant="caption"
                    component="div"
                    color='text.secondary'
                >{`${battery.percent}%`}
                </Typography>
            </Box>
        </Box>
        {icon()}
    </Stack>
    </Tooltip>
}

function BatteryPanelSmall(props: PanelProps) {
    const [data, setData] = useState(props.data as IBatteryAutomationState);

    useEventHub<IBatteryAutomationState>(data.id, updated => setData(updated));

    return <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Grey.CardTitleButton onClick={props.handleOpen}>
            <Typography className='smallCardTitle' color='secondary' fontSize='14px'>
                {data?.title}
            </Typography>
        </Grey.CardTitleButton>
        <Box sx={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px', marginBottom: '10px', textAlign: 'left' }}>
            {data.batteries.filter(i => (i.percent ?? 0) < 100).map((i, index) => BatteryStatus(i, index))}
        </Box>
    </Box>
}

function BatteryPanel(props: PanelProps) {

    const [data, setData] = useState(props.data as IBatteryAutomationState);
    const theme = useTheme();

    useEventHub<IBatteryAutomationState>(data.id, updated => setData(updated));

    return (
        <Grey.AutomationCard className={`card card-${theme.name}`} variant="outlined">
            <CardContent>
                <Grey.AutomationTitle>
                    {data.title}
                </Grey.AutomationTitle>
                <div style={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: 14, textAlign: 'left' }} color="text.secondary" gutterBottom>
                        {data.status}
                    </Typography>
                    <Box>
                        {data.batteries.filter(i => (i.percent ?? 0) < 100).map(battery => <Box key={battery.name} sx={{ display: 'inline' }}>
                            <Typography sx={{ float: 'left', color: '#A9A9A9' }}>{battery.name}: &nbsp;</Typography><Typography color={GetColorTemp(battery.percent)}>{!battery.percent ? 'unknown' : battery.percent}%</Typography>
                        </Box>)}
                    </Box>
                </div>
            </CardContent>
        </Grey.AutomationCard>
    );
}
export { BatteryPanel, BatteryPanelSmall };

