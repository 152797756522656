import { NightlightTwoTone, WbCloudy, WbSunnyTwoTone } from "@mui/icons-material";
import { Box, CardContent, Stack, Theme, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import dayjs from "dayjs";
import React, { useState } from "react";
import { Forecast, IAutomationModel, IWeatherAutomationModel, WeatherPoint } from "../../models/Models";
import { useEventHub } from "../../services/EventHub";
import { GreyGraph, Point, Series } from "../controls/GreyGraph";
import * as Grey from "../StyledComponents";
import { PanelProps } from "./PanelProps";

function GetUnit() {
    return localStorage?.getItem("weather_unit") ?? 'C';
}

function SetUnit(value: string) {
    localStorage?.setItem("weather_unit", value);
}

function ConvertTemperature(value: number, unit: string) {
    if (unit.toLocaleLowerCase() == 'c') {
        return value;
    }

    return Math.round((value * 9 / 5) + 32);
}

function WeatherPanelSmall(props: PanelProps) {
    const [data, setData] = useState<IWeatherAutomationModel>(props.data as IWeatherAutomationModel);
    const [updateTime, setUpdateTime] = useState(dayjs());
    const [date, setDate] = useState(dayjs());
    const [currentDay, setCurrentDay] = useState(data.days[0]);
    const [currentPoint, setCurrentPoint] = useState(data.now);
    const getDataSet = (d: dayjs.Dayjs) => currentDay.dataPoints
        .filter(i => dayjs(i.timestamp).hour() >= d.hour())
        .concat(data.days.length > 1 ? data.days[1].dataPoints : [])
        .slice(0, 8);
    const [currentDataSet, setCurrentDataSet] = useState(getDataSet(date));
    const [unit, setUnit] = useState(GetUnit());

    const updateAll = (updated: IAutomationModel | null) => {
        var model = updated as IWeatherAutomationModel;
        if (model) {
            setData(model);
            setCurrentDay(model.days[0]);
            if (updateTime.add(1, 'minute') < dayjs()) {
                setUpdateTime(dayjs());
                setCurrentPoint(model.now);
                setDate(dayjs());
                setCurrentDataSet(getDataSet(date));
            }
        }
    }

    useEventHub<IWeatherAutomationModel>(data.id, updateAll);

    const graphRange = {
        min: Math.min(...currentDataSet.map(i => i.temperature)) - 2,
        max: Math.max(...currentDataSet.map(i => i.temperature)) + 5
    };

    const series = [
        { data: currentDataSet.map((i, index) => new Point(index, Math.round(i.temperature))), color: '#fce4a2', area: true, label: false },
        { data: currentDataSet.map((i, index) => new Point(index, Math.round(i.temperature))), color: '#ed7d58', area: false, label: true, thickness: 5 },
        { data: currentDataSet.map((i, index) => new Point(index, normalize(i.precipitationPercent, graphRange))), color: 'lightblue', area: false, label: false, thickness: 3 },
    ] as Series[];

    return <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Grey.CardTitleButton onClick={props.handleOpen}>
            <Grey.AutomationTitleSmall className='smallCardTitle' color='primary' fontSize='14px'>
                {data?.title}
            </Grey.AutomationTitleSmall>
            <Stack direction='row' sx={{ position: 'absolute', right: '10px' }}>
                <Typography color={GetTempColor(data)} sx={{ padding: 0, float: 'right', marginRight: '10px', marginTop: '2px' }}>
                    {ConvertTemperature(data.now.temperature, unit)}°{unit}
                </Typography>
                {GetIcon(currentDay, currentPoint)}
            </Stack>
        </Grey.CardTitleButton>
        <Box sx={{ marginTop: '10px' }}>
            <GreyGraph
                series={series}
                xAxis={{ points: currentDataSet.map(i => dayjs(i.timestamp).format('h a')) }}
                width={350}
                height={80}
                onClick={(index) => setCurrentPoint(currentDataSet[index])}
                yAxisRange={graphRange} />
        </Box>
    </Box>
}

function RainAnimation() {
    return <React.Fragment><i className="rain"></i><i className="rain"></i><i className="rain rainThick"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i>
        <i className="rain"></i><i className="rain"></i><i className="rain rainThick"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i>
        <i className="rain"></i><i className="rain rainThick"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain rainThick"></i><i className="rain"></i><i className="rain"></i>
        <i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i>
        <i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i>
        <i className="rain rainThick"></i><i className="rain rainThick"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i>
        <i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i>
        <i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i>
        <i className="rain rainThick"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain rainThick"></i><i className="rain"></i><i className="rain rainThick"></i><i className="rain"></i>
        <i className="rain"></i><i className="rain rainThick"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain"></i>
        <i className="rain"></i><i className="rain"></i><i className="rain"></i><i className="rain rainThick"></i><i className="rain"></i><i className="rain"></i>
    </React.Fragment>;
}

function CloudAnimation(theme: Theme) {
    return <div className="cloudContainer" style={{ pointerEvents: 'none' }}>
        <div id="cloud-intro" className={theme.palette.mode == 'light' ? 'cloud-intro-light' : ''}></div>
    </div>
}

function GetBackground(data: WeatherPoint, theme: Theme) {
    if (data.precipitationPercent > 40) {
        return RainAnimation();
    }
    if (data.cloudCoveragePercent > 40) {
        return CloudAnimation(theme);
    }
    // if (data.description == 'sunny') {
    //     return <div className='sun' style={{ opacity: .1, bottom: '170px', left: '90px' }}></div>
    // }
    return null;
}

function GetIcon(day: Forecast, data: WeatherPoint) {
    if (data.precipitationPercent > 40) {
        return <img src='../rainy.png' style={{ float: 'right', filter: 'invert(70%)', width: '24px', height: '24px' }} />
    }
    if (data.cloudCoveragePercent > 40) {
        return <WbCloudy sx={{ color: '#d7d8db', float: 'right', height: '28px' }} />;
    }

    const currentTime = dayjs();
    const sunrise = dayjs(day.sunrise);
    const sunset = dayjs(day.sunset);
    if (currentTime > sunrise && currentTime < sunset) {
        return <WbSunnyTwoTone sx={{ color: '#ebd56c', float: 'right', height: '28px' }} />;
    }
    return <NightlightTwoTone htmlColor="#d9d38d" sx={{ float: 'right', height: '28px' }} />;
}

function GetTempColor(data: IWeatherAutomationModel) {
    if (data.now.temperature < 30) {
        return '#59a3e3';
    }
    return '#e37259';
}

const normalize = (value: number, graphRange: { min: number, max: number }) => (value * (graphRange.max - graphRange.min) / 100) + graphRange.min

function WeatherPanel(props: PanelProps) {
    const [data, setData] = useState<IWeatherAutomationModel>(props.data as IWeatherAutomationModel);
    const [updateTime, setUpdateTime] = useState(dayjs());
    const [date, setDate] = useState(dayjs());
    const [currentDay, setCurrentDay] = useState(data.days[0]);
    const [currentPoint, setCurrentPoint] = useState(data.now);
    const theme = useTheme();

    const getDataSet = (d: dayjs.Dayjs) => currentDay.dataPoints
        .filter(i => dayjs(i.timestamp).hour() >= d.hour())
        .concat(data.days.length > 1 ? data.days[1].dataPoints : [])
        .slice(0, 8);
    const [currentDataSet, setCurrentDataSet] = useState(getDataSet(date));
    const [unit, setUnit] = useState(GetUnit());

    const updateAll = (updated: IAutomationModel | null) => {
        var model = updated as IWeatherAutomationModel;
        if (model) {
            setData(model);
            setCurrentDay(model.days[0]);
            if (updateTime.add(1, 'minute') < dayjs()) {
                setUpdateTime(dayjs());
                setCurrentPoint(model.now);
                setDate(dayjs());
                setCurrentDataSet(getDataSet(date));
            }
        }
    }

    useEventHub<IWeatherAutomationModel>(data.id, updateAll);

    const graphRange = {
        min: Math.min(...currentDataSet.map(i => i.temperature)) - 2,
        max: Math.max(...currentDataSet.map(i => i.temperature)) + 5
    };

    // const poly = new Polyfit(currentDataSet.map((i, index) => index), currentDataSet.map(i => i.temperature));
    // const f = poly.getPolynomial(7);
    // console.log(getRange(0, currentDataSet.length, .8).map(x => new Point(x, f(x))));
    const series = [
        { data: currentDataSet.map((i, index) => new Point(index, Math.round(i.temperature))), color: '#fce4a2', area: true, label: false },
        {
            data: currentDataSet.map((i, index) => new Point(index, Math.round(i.temperature))), color: '#ed7d58', area: false,
            label: true, thickness: 5, labelFormat: (value: number) => ConvertTemperature(value, unit)
        },
        { data: currentDataSet.map((i, index) => new Point(index, normalize(i.precipitationPercent, graphRange))), color: 'lightblue', area: false, label: false, thickness: 3 },
    ] as Series[];

    const changeDate = (hour: number) => {
        setDate(date.add(hour, 'hour'));
        setCurrentDataSet(getDataSet(date))
    }

    const toggleUnits = () => {
        SetUnit(unit == 'C' ? 'F' : 'C');
        setUnit(unit == 'C' ? 'F' : 'C');
    }

    return (<Grey.AutomationCard key={data.id} className={`card card-${theme.name}`} variant="outlined">
        <CardContent className='rainContainer'>
            <Grey.AutomationTitle>
                {data.title}
                {GetIcon(currentDay, currentPoint)}
                <Grey.CustomButton onClick={toggleUnits} sx={{ padding: 0, float: 'right', marginRight: '0px', marginTop: '3px' }}>
                    <Typography color={GetTempColor(data)} >
                        {ConvertTemperature(data.now.temperature, unit)}°{unit}
                    </Typography>
                </Grey.CustomButton>
            </Grey.AutomationTitle>

            <Box sx={{ float: 'right' }}>
                <Typography textAlign='right'>{currentPoint.description}</Typography>
                <Typography color='#d67f04' textAlign='right'>sunset: {dayjs(currentDay.sunset).format("h:mm a")}</Typography>
            </Box>

            {currentPoint.timestamp === data.now.timestamp ?
                <Typography color='#f0c15d' textAlign='left'>
                    {ConvertTemperature(currentDay.minTemperature, unit)}°{unit} - {ConvertTemperature(currentDay.maxTemperature, unit)}°C
                </Typography> :
                <Typography color='#f0c15d' textAlign='left'>{currentPoint.temperature}°{unit}</Typography>}

            <Typography color='#59a3e3' textAlign='left'>Rain: {currentPoint.precipitationPercent}%</Typography>

            {currentPoint.timestamp === data.now.timestamp ?
                <Typography color='#AAAAAA' textAlign='left' fontSize={14}>Updated at {dayjs(data.updateTime).format("h:mm a")}</Typography> :
                <Typography color='#AAAAAA' textAlign='left'>{dayjs(currentPoint.timestamp).format("h:mm a")}</Typography>}

            <Box sx={{ marginTop: '10px' }}>
                <GreyGraph
                    series={series}
                    xAxis={{ points: currentDataSet.map(i => dayjs(i.timestamp).format('h a')) }}
                    width={310}
                    height={100}
                    onClick={(index) => setCurrentPoint(currentDataSet[index])}
                    yAxisRange={graphRange} />
            </Box>
            {/* <IconButton sx={{ margin: 0, padding: 0 }} onClick={() => changeDate(-1)}><ArrowLeftOutlined /></IconButton>
            <IconButton sx={{ margin: 0, padding: 0 }} onClick={() => changeDate(1)}><ArrowRightOutlined /></IconButton> */}
            {GetBackground(currentPoint, theme)}
        </CardContent>
    </Grey.AutomationCard>
    );
}

export { WeatherPanel, WeatherPanelSmall };

