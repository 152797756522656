import { WifiOff, WifiOutlined } from "@mui/icons-material";
import { Box, CardContent, Stack, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useState } from "react";
import { IHealthCheckState } from "../../models/Models";
import { useEventHub } from "../../services/EventHub";
import * as Grey from "../StyledComponents";
import { PanelProps } from "./PanelProps";

function HealthCheckPanelSmall(props: PanelProps) {
    const [data, setData] = useState(props.data as IHealthCheckState);

    useEventHub<IHealthCheckState>(data.id, setData);

    return <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Grey.CardTitleButton onClick={props.handleOpen}>
            <Typography className='smallCardTitle' color='secondary' fontSize='14px'>
                {data?.title}
            </Typography>
        </Grey.CardTitleButton>
        <Box sx={{ marginTop: '3px', marginBottom: '10px', marginLeft: '15px', marginRight: '10px', textAlign: 'left' }}>
            <Box sx={{ display: 'block', marginTop: '20px' }}>
                {data.devices.filter(i => !i.isConnected).map(device =>
                    <Stack direction='row' key={device.id}>
                        <Typography sx={{ color: '#A9A9A9' }}>{device.name}: &nbsp;</Typography>{device.isConnected ? <WifiOutlined htmlColor="#208c44" /> : <WifiOff color='warning' />}
                    </Stack>)}
            </Box>
        </Box>
    </Box>
}

function HealthCheckPanel(props: PanelProps) {

    const [data, setData] = useState(props.data as IHealthCheckState);
    const theme = useTheme();

    useEventHub<IHealthCheckState>(data.id, setData);

    return (
        <Grey.AutomationCard className={`card card-${theme.name}`} variant="outlined">
            <CardContent>
                <Grey.AutomationTitle>
                    {data.title}
                </Grey.AutomationTitle>
                <div style={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: 14, textAlign: 'left' }} color="text.secondary" gutterBottom>
                        {data.status}
                    </Typography>
                    <Box>
                        {data.devices.map(device =>
                            <Stack direction='row' key={device.id}>
                                <Typography sx={{ color: '#A9A9A9' }}>{device.name}: &nbsp;</Typography>{device.isConnected ? <WifiOutlined htmlColor="#208c44" /> : <WifiOff color='warning' />}
                            </Stack>)}
                    </Box>
                </div>
            </CardContent>
        </Grey.AutomationCard>
    );
}
export { HealthCheckPanel, HealthCheckPanelSmall };

