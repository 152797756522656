import { DayOfWeek, GroupType, ScheduleConfigModel, SetpointSetting, SettingsModel } from "../models/Models";
import { AppService } from "./AppService";

export class ConfigService {
    public async GetSettings(): Promise<SettingsModel> {
        const response = await fetch(AppService.Host + 'api/settings');
        return response.ok ? await response.json() : null;
    }

    public async setSettings(settings: SettingsModel): Promise<SettingsModel> {
        const response = await fetch(AppService.Host + 'api/settings', {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST', body: JSON.stringify(settings)
        });
        return response.ok ? await response.json() : null;
    }

    public async getSchedule(id: string): Promise<ScheduleConfigModel> {
        const response = await fetch(AppService.Host + `api/schedule/${id}`);
        return response.ok ? await response.json() : null;
    }

    public async updateGroupType(automationId: string, groupType: GroupType): Promise<ScheduleConfigModel | null> {
        const response = await fetch(AppService.Host + `api/schedule/${automationId}/updateGroupType?groupType=${groupType}`, {
            method: 'POST'
        });
        return response.ok ? await response.json() : null;
    }

    public async updateTime(automationId: string, dayOfWeek: DayOfWeek, setting: SetpointSetting): Promise<ScheduleConfigModel | null> {
        const response = await fetch(AppService.Host + `api/schedule/${automationId}/${dayOfWeek}/updateTime`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST', body: JSON.stringify(setting)
        });
        return response.ok ? await response.json() : null;
    }

    public async addTime(automationId: string, dayOfWeek: DayOfWeek, setting: SetpointSetting): Promise<SetpointSetting | null> {
        const response = await fetch(AppService.Host + `api/schedule/${automationId}/${dayOfWeek}/addTime`, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'POST', body: JSON.stringify(setting)
        });
        return response.ok ? await response.json() : null;
    }

    public async deleteTime(automationId: string, dayOfWeek: DayOfWeek, setting: SetpointSetting): Promise<ScheduleConfigModel | null> {
        const response = await fetch(AppService.Host + `api/schedule/${automationId}/${dayOfWeek}/${setting.id}`, {
            headers: {},
            method: 'DELETE'
        });
        return response.ok ? await response.json() : null;
    }
}

export const configService = new ConfigService();