import { AlarmOnOutlined, Clear, ReportOutlined, ReportProblemTwoTone, TimerOutlined } from "@mui/icons-material";
import { Box, CardContent, Divider, IconButton, Stack, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import Countdown, { zeroPad } from "react-countdown";
import { IAutomationModel, ITimersAutomationModel } from "../../models/Models";
import { appService } from "../../services/AppService";
import { eventHubInstance, useEventHub } from "../../services/EventHub";
import * as Grey from "../StyledComponents";
import { ThemeService } from "../../services/ThemeService";
import { PanelProps } from "./PanelProps";

function AlertIcon(priority: string) {
    if (priority === "Low") {
        return <TimerOutlined sx={{ marginRight: '5px', float: 'left' }} />;
    }
    else if (priority === "Medium") {
        return <ReportProblemTwoTone sx={{ marginRight: '5px', float: 'left' }} color='warning' />;
    }

    return <ReportOutlined sx={{ marginRight: '5px', float: 'left' }} color='error' />;
}

//@ts-ignore
const renderer = ({ hours, minutes, seconds }) => {
    let time = "";
    if (hours > 0) {
        time = `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`
    }
    else {
        time = `${zeroPad(minutes)}:${zeroPad(seconds)}`
    }

    return time;
};

function TimerPanelSmall(props: PanelProps) {
    const [data, setData] = useState(props.data as ITimersAutomationModel);

    const resolveTimer = async (id: string) => {
        var result = await appService.cancelTimer(data.id, id);
        updateAll(result);
    }

    const updateAll = (model: ITimersAutomationModel | null) => {
        if (model) {
            setData(model);
        }
    }
    useEventHub<ITimersAutomationModel>(data.id, updateAll);

    return <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Box sx={{ display: 'block', marginTop: '10px', marginX: '15px' }}>
            {data.runningTimers.map((timer, i) =>
                <Box key={i}>
                    <Typography sx={{}}>
                        {AlertIcon("Low")}
                        {timer.message}
                        &nbsp;
                        <Countdown date={dayjs(timer.endTime).toDate()} renderer={renderer} />
                        <Grey.CustomIconButton onClick={() => resolveTimer(timer.id)} sx={{ float: 'right', padding: '0px' }} size="small" color="error"><Clear /></Grey.CustomIconButton>
                    </Typography>
                    {i != data.runningTimers.length - 1 ? <Divider sx={{ marginY: '5px' }} /> : <Box sx={{ marginBottom: '8px' }} />}
                </Box>
            )}
        </Box>
    </Box>
}

function TimerPanel(props: PanelProps) {
    const [data, setData] = useState(props.data as ITimersAutomationModel);
    const [isInactive, setIsInactive] = useState(data?.isInactive ?? false);
    const theme = useTheme();

    const resolveTimer = async (id: string) => {
        var result = await appService.cancelTimer(data.id, id);
        updateAll(result);
    }

    const startTimer = async (id: string) => {
        var result = await appService.startTimer(data.id, id);
        updateAll(result);
    }

    const updateAll = (state: IAutomationModel | null) => {
        const model = state as ITimersAutomationModel;
        if (model) {
            setData(model);
            setIsInactive(model.isInactive);
            if (model.runningTimers.length == 1) {
                const now = dayjs();
                const end = dayjs(model.runningTimers[0].endTime);
                let hours = end.diff(now, 'hours');
                let minutes = end.diff(now, 'minutes');
                let seconds = end.diff(now, "seconds");
                const days = Math.floor(hours / 24);
                hours = hours - (days * 24);
                minutes = minutes - (hours * 60);
                seconds = seconds - (minutes * 60);
                document.title = `House - ${model.runningTimers[0].message} in ${renderer({ hours, minutes, seconds })}`;
            }
            else {
                document.title = "House";
            }
        }
    }

    useEventHub<ITimersAutomationModel>(data.id, updateAll);

    return (
        <Grey.AutomationCard className={`card card-${theme.name}`} variant="outlined">
            <CardContent>
                <Grey.AutomationTitle>
                    {data.title}
                </Grey.AutomationTitle>
                <div style={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: 14, textAlign: 'left' }} color="text.secondary" gutterBottom>
                        {data.status}
                    </Typography>
                    <Box>
                        {data.runningTimers.map(timer =>
                            <Box key={timer.id}>
                                <Box>
                                    <Typography sx={{ marginY: '5px' }}>
                                        {timer.message}
                                        &nbsp;
                                        <Countdown date={dayjs(timer.endTime).toDate()} renderer={renderer} />
                                        <Grey.CustomIconButton onClick={() => resolveTimer(timer.id)} sx={{ paddingY: '0px', paddingX: '5px', float: 'right' }} size="small" color="error"><Clear /></Grey.CustomIconButton>
                                    </Typography>
                                </Box>
                                <Divider sx={{ marginY: '5px' }} />
                            </Box>
                        )}
                        {data.runningTimers.length > 0 ? <Divider sx={{ marginY: '5px' }} /> : null}
                        {data.timers.map(timer =>
                            <Stack direction='row' key={timer.id}>
                                <Grey.CustomButton
                                    onClick={() => startTimer(timer.id)}
                                    sx={{ padding: '5px', marginRight: '5px' }} size="small">
                                    <AlarmOnOutlined />
                                    <Typography sx={{ marginTop: '2px', marginLeft: '5px' }}>
                                        {timer.message}
                                    </Typography>
                                </Grey.CustomButton>
                            </Stack>
                        )}
                    </Box>
                </div>
            </CardContent>
        </Grey.AutomationCard>
    );
}
export { TimerPanel, TimerPanelSmall };

