import { AirTwoTone, DoorFront, IronSharp, MeetingRoom, PowerSettingsNewTwoTone, PowerSharp, PrintSharp, RotateLeft, SpeedTwoTone, ThermostatTwoTone, WarningAmber, WaterDropTwoTone } from "@mui/icons-material";
import { Box, CardContent, Divider, FormControlLabel, Grid, Stack, Switch, SxProps, Theme, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import { ContactSensorState, IContactSensorsAutomationModel, PlugState } from "../../models/Models";
import { appService } from "../../services/AppService";
import { useEventHub } from "../../services/EventHub";
import * as Grey from "../StyledComponents";
import { PanelProps } from "./PanelProps";
import { SettingsMenuButton } from "../configs/SettingsMenuButton";
import { ComponentUnavailable } from "../controls/ComponentUnavailable";

const icon = (plug: PlugState) => {
    if (plug.name.toLowerCase().includes('iron')) {
        return <IronSharp htmlColor={plug.isOn ? '#d9a536' : ''} />
    }

    if (plug.name.toLowerCase().includes('printer')) {
        return <PrintSharp htmlColor={plug.isOn ? '#d9a536' : ''} />
    }

    return <PowerSharp htmlColor={plug.isOn ? '#d9a536' : ''} />
};

function PlugStatus(plug: PlugState) {
    return <Stack key={plug.entityId} direction='column' sx={{ position: 'relative', display: 'inline-flex', marginRight: '10px' }}>
        <Typography textAlign='center' sx={{}}>{plug.name}</Typography>
        <Grey.CustomIconButton>{icon(plug)}</Grey.CustomIconButton>
    </Stack>
}

export function ContactSensorsPanelSmall(props: PanelProps) {
    const [data, setData] = useState(props.data as IContactSensorsAutomationModel);

    useEventHub<IContactSensorsAutomationModel>(data.id, setData);

    return <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Grey.CardTitleButton onClick={props.handleOpen}>
            <Typography className='smallCardTitle' color='secondary' fontSize='14px'>
                {data?.title}
            </Typography>
        </Grey.CardTitleButton>
        <Box sx={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px', marginBottom: '10px', textAlign: 'left' }}>
            {data.sensors?.map(sensor => <ContactSensorView key={sensor.entityId} automation={props.data as IContactSensorsAutomationModel} sensor={sensor} />)}
        </Box>
    </Box>
}

export function ContactSensorView(props: { automation?: IContactSensorsAutomationModel, sensor: ContactSensorState, sx?: SxProps<Theme> }) {

    if (!props.sensor.isAvailable) {
        return <Box sx={props.sx}>
            <Typography sx={{ opacity: .75 }}>{props.sensor.friendlyName ?? props.sensor.entityId}</Typography>
            <ComponentUnavailable name='Sensor' sx={{ marginY: '2px' }} />
            <Divider />
        </Box>
    }
    return <Box sx={props.sx}>
        <Typography sx={{ opacity: .75 }}>{props.sensor.friendlyName}</Typography>
        <Stack direction='row' sx={{ marginTop: '5px', marginLeft: '0px', marginBottom: '5px' }}>
            {props.sensor.hasContact ? <DoorFront /> : <MeetingRoom htmlColor="#298f54" />}
            {props.sensor.temperature ?
                <Box sx={{ marginRight: '8px', display: 'inline-flex' }}>
                    <ThermostatTwoTone htmlColor="#eb8034" />
                    <Typography>{props.sensor.temperature}°F</Typography>
                </Box> : null
            }
            {props.sensor.humidity ?
                <Box sx={{ marginRight: '8px', display: 'inline-flex' }}>
                    <WaterDropTwoTone htmlColor="#2b78c4" />
                    <Typography>{props.sensor.humidity}%</Typography>
                </Box> : null
            }
            {props.sensor.pressure ?
                <Box sx={{ marginRight: '8px', display: 'inline-flex' }}>
                    <SpeedTwoTone htmlColor="#929da8" />
                    <Typography>{props.sensor.pressure} mb</Typography>
                </Box> : null
            }
            {props.automation ? <Grey.CustomIconButton sx={{ padding: '0px' }} onClick={() => appService.ContactSensor.calibrateClosed(props.automation!.id, props.sensor.entityId)}><RotateLeft /></Grey.CustomIconButton> : null}
        </Stack>
        <Divider />
    </Box>
}

export function ContactSensorsPanel(props: PanelProps) {

    const [data, setData] = useState(props.data as IContactSensorsAutomationModel);
    const theme = useTheme();

    useEventHub<IContactSensorsAutomationModel>(data.id, setData);

    return (
        <Grey.AutomationCard className={`card card-${theme.name}`} variant="outlined">
            <CardContent>
                <Grey.AutomationTitle>
                    {data.title}
                    <SettingsMenuButton automation={data} />
                </Grey.AutomationTitle>
                <div style={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: 14, textAlign: 'left' }} color="text.secondary" gutterBottom>
                        {data.status}
                    </Typography>
                    <Box sx={{ marginTop: '0px', marginLeft: '0px' }}>
                        {data.sensors?.map(sensor => <ContactSensorView key={sensor.entityId} automation={data} sensor={sensor} />)}
                    </Box>
                </div>
            </CardContent>
        </Grey.AutomationCard>
    );
}

