import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { green, orange, purple, red, yellow } from '@mui/material/colors';
import { Box, BoxProps, Button, ButtonProps, Card, CardContent, CardContentProps, CardProps, Chip, ChipProps, IconButton, IconButtonProps, Slider, SliderProps, SpeedDial, SpeedDialProps, ToggleButton, ToggleButtonProps, Typography, TypographyProps } from '@mui/material';
import { PowerSettingsNewTwoTone } from '@mui/icons-material';

declare module '@mui/material/styles' {
    interface Theme {
        name: string,
        page: {
            background: string;
            backgroundImage?: string;
            mobileBackground: string;
        }
        appBar: {
            color: string;
            background: string;
            boxShadow?: string;
        },
        card: {
            title: string;
            background: string;
            modalBackground: string;
            borderRadius?: string;
            backgroundImage?: string;
        };
        iconButton: {
            color: string;
        };
        action: {
            chipColor: string;
            chipBackgroundColor: string;
            chipHover?: string;
            color: string;
            toggleColor: string;
            hoverColor: string;
            containedColor: string;
            selectedColor: string;
        };
        slider?: {
            defaultLightColor?: string;
        };
        powerIcon: JSX.Element;
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        name: string,
        page: {
            background: string;
            backgroundImage?: string;
            mobileBackground: string;
        },
        appBar: {
            color: string;
            background: string;
            boxShadow?: string;
        },
        card?: {
            title?: string;
            background?: string;
            modalBackground?: string;
            borderRadius?: string;
            backgroundImage?: string;
        };
        iconButton?: {
            color?: string;
        };
        action?: {
            chipColor?: string;
            chipBackgroundColor?: string;
            chipHover?: string;
            color?: string;
            toggleColor?: string;
            hoverColor?: string;
            containedColor?: string;
            selectedColor?: string;
        };
        slider?: {
            defaultLightColor?: string;
        };
        powerIcon: JSX.Element;
    }
}

export function DarkTheme() {
    return createTheme({
        name: 'Dark',
        page: {
            background: '#282c34',
            mobileBackground: '#282c34',
        },
        typography: {
            fontFamily: "Comfortaa, Arial, Helvetica, sans-serif",
            button: { textTransform: 'none' }
        },
        palette: {
            mode: 'dark',
            background: {
                paper: '#121212',
            },
            text: {
                primary: 'rgba(255, 255, 255, .7)',
                secondary: '#b5c0c7',
            },
        },
        iconButton: {
            color: '#bdd9ff',
        },
        appBar: {
            color: '#E0E0E0',
            background: '#121212'
        },
        card: {
            title: '#eda4e0',
            background: '#323a3d',
            modalBackground: '#323a3d'
        },
        action: {
            chipBackgroundColor: '#112a36',
            containedColor: '#121212',
        },
        powerIcon: <PowerSettingsNewTwoTone />,
    });
}

export function LightTheme() {
    return createTheme({
        name: 'Light',
        page: {
            background: '#dedede',
            mobileBackground: '#dedede',
        },
        typography: {
            fontFamily: "Comfortaa, Arial, Helvetica, sans-serif",
            button: { textTransform: 'none' }
        },
        palette: {
            mode: 'light',
            background: {
                paper: '#f1f1f1',
            },
            text: {
                primary: '#111',
                secondary: '#606a70',
            },
        },
        iconButton: {
            color: '#2a3387',
        },
        appBar: {
            color: '#121212',
            background: '#ead9ff'
        },
        card: {
            title: '#46646e',
            background: '#f1f1f1',
            modalBackground: '#f1f1f1'
        },
        action: {
            chipColor: '#121212',
            chipBackgroundColor: '#acd5e8',
            chipHover: '#f9deff !important',
            containedColor: '#efefef',
        },
        slider: {
            defaultLightColor: '#6b9ed1'
        },
        powerIcon: <PowerSettingsNewTwoTone />,
    });
}

export function HallowTheme() {
    return createTheme({
        name: 'Halloween',
        page: {
            background: '#282c34',
            mobileBackground: '#072424',
        },
        typography: {
            fontFamily: "Comfortaa, Arial, Helvetica, sans-serif",
            button: { textTransform: 'none' }
        },
        palette: {
            mode: 'dark',
            background: {
                paper: '#121212',
            },
            text: {
                primary: orange[500],
                secondary: '#d18fff',
            }
        },
        appBar: {
            color: '#E0E0E0',
            background: '#121212'
        },
        card: {
            title: 'rgba(209, 202, 134, .8)',
            background: '#160024',
            modalBackground: '#222'
        },
        iconButton: {
            color: green[500],
        },
        action: {
            chipBackgroundColor: 'rgba(114, 163, 151, .1)',
            color: green[700],
            toggleColor: '#fa9269',
            containedColor: orange[500],
            selectedColor: 'rgba(0, 200, 0, .3)',
            hoverColor: '#360105',
        },
        powerIcon: <div style={{ fontSize: 18, lineHeight: 1.3, color: 'rgba(0, 0, 0, .8)' }}>💀</div>,
    });
}

export function ChristmasTheme() {
    return createTheme({
        name: 'Christmas',
        page: {
            background: '#282c34',
            mobileBackground: '#210003',
        },
        typography: {
            fontFamily: "Comfortaa, Arial, Helvetica, sans-serif",
        },
        palette: {
            mode: 'dark',
            background: {
                paper: '#121212',
            },
            text: {
                primary: '#d9f0ff',
                secondary: '#d9f0ff',
            }
        },
        appBar: {
            color: '#E0E0E0',
            background: '#121212'
        },
        card: {
            title: '#ff8473',
            background: '#001d26',
            modalBackground: '#222'
        },
        iconButton: {
            color: '#94e6ff',
        },
        action: {
            chipColor: '#d4f5ff',
            chipBackgroundColor: 'rgba(114, 163, 151, .1)',
            color: '#45b2d1',
            toggleColor: '#14b57d',
            containedColor: '#0000FF',
            selectedColor: '#235E6F99',
            hoverColor: '#235E6F',
        },
        powerIcon: <PowerSettingsNewTwoTone />,
    });
}

export function AeroTheme() {
    return createTheme({
        name: 'Aero',
        page: {
            background: 'transparent',
            backgroundImage: "url('../aero.jpg')",
            mobileBackground: '#dedede',
        },
        typography: {
            fontFamily: "Comfortaa, Arial, Helvetica, sans-serif",
            button: { textTransform: 'none' }
        },
        palette: {
            mode: 'light',
            background: {
                paper: '#f1f1f1',
            },
            text: {
                primary: '#111',
                secondary: '#606a70',
            },
        },
        iconButton: {
            color: '#2a3387',
        },
        appBar: {
            color: '#121212',
            background: '#ffd1fe',
            boxShadow: 'none',
        },
        card: {
            title: '#630839',
            background: 'linear-gradient(to bottom, #e3f7fa7f, #e3f7fad0, #e3f7fa7f)',
            modalBackground: '#f1f1f1',
            borderRadius: '20px',
        },
        action: {
            chipColor: '#121212',
            chipBackgroundColor: 'linear-gradient(to bottom, transparent 50%, #e67c97 50%), linear-gradient(to bottom, #ffa3b1, #e67c97)',
            chipHover: '#f9deff !important',
            containedColor: '#121212',
        },
        slider: {
            defaultLightColor: '#6b9ed1'
        },
        powerIcon: <PowerSettingsNewTwoTone />,
    });
}

const AutomationTitle = styled(Box)<BoxProps>(({ theme }) => ({
    color: theme.card.title,
    textAlign: 'left',
    fontWeight: 'bold',
    marginBottom: '10px',
}));

export { AutomationTitle };

const AutomationTitleSmall = styled(Typography)<TypographyProps>(({ theme }) => ({
    color: theme.card.title,
}));

export { AutomationTitleSmall };

const AutomationCard = styled(Card)<CardProps>(({ theme }) => ({
    background: theme.card.background,
    borderRadius: theme.card.borderRadius ?? '5px',
    backgroundImage: theme.card.backgroundImage ?? '',
    backdropFilter: theme.name == 'Aero' ? 'blur(4px)' : '',
    border: theme.name == 'Aero' ? '1px solid rgba(240, 176, 253, 0.3)' : 'none',
    boxShadow: theme.name == 'Aero' ? '5px 5px 5px 0px rgba(0,0,0,0.3)' : '2px 2px 2px 0px rgba(0,0,0,0.3)',
}));

export { AutomationCard };

const CustomIconButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
    color: theme.iconButton?.color,
}));

export { CustomIconButton };

const CustomSlider = styled(Slider)<SliderProps>(({ theme }) => ({
    color: theme.action?.color,
    '& .MuiSlider-thumb': {
        width: 12,
        height: 12,
        transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
        '&:before': {
            boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
        },
        '&:hover, &.Mui-focusVisible': {
            boxShadow: `0px 0px 0px 8px ${'rgb(255 255 255 / 16%)'
                }`,
        }
    }
}));

export { CustomSlider };

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
    'color': theme.iconButton?.color,
    '&:hover': {
        backgroundColor: theme?.action?.hoverColor
    },
    '&.MuiButton-contained': {
        'color': theme.action?.containedColor,
        backgroundColor: theme?.action?.selectedColor
    },
    '.MuiTouchRipple-child': {
        backgroundColor: theme?.action?.hoverColor
    }
}));

export { CustomButton };

const CardTitleButton = styled(Button)<ButtonProps>(({ theme }) => ({
    margin: 0,
    padding: 0,
    width: '100%',
    borderBottom: '1px solid #090909',
    borderRadius: '0px'
}));

export { CardTitleButton };

const CustomToggleButton = styled(ToggleButton)<ToggleButtonProps>(({ theme }) => ({
    'color': theme.action?.toggleColor,
    '&:hover': {
        backgroundColor: theme?.action?.hoverColor
    },
    '&.Mui-selected': {
        'color': theme.action?.toggleColor,
        backgroundColor: theme?.action?.selectedColor
    },
    '.MuiTouchRipple-child': {
        backgroundColor: theme?.action?.hoverColor
    }
}));

export { CustomToggleButton };

const CustomSpeedDial = styled(SpeedDial)<SpeedDialProps>(({ theme }) => ({
    '& .MuiButtonBase-root': { backgroundColor: theme.action?.color }
}));

export { CustomSpeedDial };

const ThemedChip = styled(Chip)<ChipProps>(({ theme }) => ({
    'background': theme.action.chipBackgroundColor,
    'color': theme.action.chipColor,
    '&:hover': { background: theme.action.chipHover }
}));

export { ThemedChip };

