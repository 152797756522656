import { IronSharp, PowerSharp, PrintSharp } from "@mui/icons-material";
import { Box, CardContent, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IRemotesAutomationModel, PlugState } from "../../models/Models";
import { useEventHub } from "../../services/EventHub";
import { UseWindowDimensions } from "../../services/Utils";
import { IrCommandsModal } from "../controls/IrCommandsModal";
import * as Grey from "../StyledComponents";
import { SettingsMenuButton } from "../configs/SettingsMenuButton";
import { PanelProps } from "./PanelProps";

const icon = (plug: PlugState) => {
    if (plug.name.toLowerCase().includes('iron')) {
        return <IronSharp htmlColor={plug.isOn ? '#d9a536' : ''} />
    }

    if (plug.name.toLowerCase().includes('printer')) {
        return <PrintSharp htmlColor={plug.isOn ? '#d9a536' : ''} />
    }

    return <PowerSharp htmlColor={plug.isOn ? '#d9a536' : ''} />
};

function RemoteView(props: { data: IRemotesAutomationModel, remoteId: string }) {
    const [remoteName, setRemoteName] = useState(props.data.irSensors ? props.data.irSensors[props.remoteId] : '');
    const [irCommandsOpen, setIrCommandsOpen] = React.useState(false);

    const navigate = useNavigate();
    const windowSize = UseWindowDimensions();

    const handleIrCommandsOpen = async () => {
        try {
            if (windowSize.width < 700) {
                navigate(`/irconfig/${props.data.id}/${props.remoteId}`);
                return;
            }

            setIrCommandsOpen(true);
        }
        catch {
        }
    }

    return <Box sx={{ marginRight: '10px' }}>
        <Grey.ThemedChip onClick={handleIrCommandsOpen} label={remoteName} />
        <IrCommandsModal isOpen={irCommandsOpen} onClose={() => setIrCommandsOpen(false)} automation={props.data} irSensorId={props.remoteId} />
    </Box>
}

function RemotesPanelSmall(props: PanelProps) {
    const [data, setData] = useState(props.data as IRemotesAutomationModel);

    useEventHub<IRemotesAutomationModel>(data.id, setData);

    return <Box sx={{ flexGrow: 1, position: 'relative', textAlign: 'left' }}>
        <Grey.CardTitleButton onClick={props.handleOpen}>
            <Typography className='smallCardTitle' color='secondary' fontSize='14px'>
                {data?.title}
            </Typography>
        </Grey.CardTitleButton>
        <Box sx={{ marginTop: '10px', marginLeft: '10px', marginRight: '10px', marginBottom: '10px', textAlign: 'left', display: 'inline-flex' }}>
            {Object.keys(data.irSensors ?? {})?.map(remoteId => <RemoteView key={remoteId} data={data} remoteId={remoteId} />)}
        </Box>
    </Box>
}

function RemotesPanel(props: PanelProps) {

    const [data, setData] = useState(props.data as IRemotesAutomationModel);
    const theme = useTheme();

    useEventHub<IRemotesAutomationModel>(data.id, setData);

    return (
        <Grey.AutomationCard className={`card card-${theme.name}`} variant="outlined">
            <CardContent>
                <Grey.AutomationTitle>
                    {data.title}
                    <SettingsMenuButton automation={data} />
                </Grey.AutomationTitle>
                <Box style={{ textAlign: "left" }}>
                    <Typography sx={{ fontSize: 14, textAlign: 'left' }} color="text.secondary" gutterBottom>
                        {data.status}
                    </Typography>
                    <Box sx={{ marginTop: '0px', marginLeft: '0px', display: 'inline-flex' }}>
                        {Object.keys(data.irSensors ?? {})?.map(remoteId => <RemoteView key={remoteId} data={data} remoteId={remoteId} />)}
                    </Box>
                </Box>
            </CardContent>
        </Grey.AutomationCard>
    );
}
export { RemotesPanel, RemotesPanelSmall };

