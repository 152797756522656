import { Box, Typography, useTheme } from '@mui/material';
import { useLoaderData } from 'react-router-dom';
import { IAutomationModel, ScheduleConfigModel } from '../../models/Models';
import { appService } from '../../services/AppService';
import { configService } from '../../services/ConfigService';
import { IsAndroidApp } from '../../services/Utils';
import { IRCommandsPanelSmall } from '../configs/IRCommandsPanel';
import { SchedulePanelSmall } from '../configs/SchedulePanel';

export interface Props {
    automation: IAutomationModel;
    config: ScheduleConfigModel;
    sensorId: string;
}

//@ts-ignore
export async function automationConfigLoader({ params }) {
    try {
        const a = await appService.Automations.getAutomation(params.automationId);
        if (!a || a.id != params.automationId) {
            throw TypeError("eep")
        }

        const config = await configService.getSchedule(a.id)
        if (!config) {
            throw TypeError("Could not connect to server.")
        }

        //@ts-ignore
        //@ts-ignore
        return { automation: a, config: config };
    } catch (error) {
        throw TypeError("Could not connect to server.");
    }
}

export function AutomationConfigPage() {
    const theme = useTheme();
    const props = useLoaderData() as Props;

    return <Box sx={{ marginRight: IsAndroidApp() ? '23px' : '0px' }}>
        <Typography color={theme.palette.text.primary} variant="h6" component="h2" sx={{ marginTop: '10px' }}>
            {props.config?.title}
        </Typography>
        <SchedulePanelSmall automation={props.automation} data={props.config} type={props.automation.type} />
    </Box>;
}

//@ts-ignore
export async function automationIRConfigLoader({ params }) {
    try {
        const a = await appService.Automations.getAutomation(params.automationId);
        if (!a || a.id != params.automationId) {
            throw TypeError("eep")
        }

        //@ts-ignore
        //@ts-ignore
        return { automation: a, sensorId: params.sensorId };
    } catch (error) {
        throw TypeError("Could not connect to server.");
    }
}

export function AutomationIRConfigPage() {
    const theme = useTheme();
    const props = useLoaderData() as Props;

    return <Box>
        <Typography color={theme.palette.text.primary} variant="h6" component="h2" sx={{ marginTop: '10px' }}>
            {props.config?.title}
        </Typography>
        <IRCommandsPanelSmall automation={props.automation} type={props.automation.type} sensorId={props.sensorId} />
    </Box>;
}

