import { Box, Stack, Theme, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { AutomationType, ESCatFeeder, ESContactSensor, ESLight, ESModel, ESPlant, ESThermostat, IAutomationModel, IContactSensorsAutomationModel } from "../../models/Models";
import { appService } from "../../services/AppService";
import { GetRange, GreyGraph, Point, Series, XAxis, YAxis } from "../controls/GreyGraph";
import * as Grey from "../StyledComponents";

interface Props {
    automation: IAutomationModel,
    data: ESModel[],
}

function getGraph(automation: IAutomationModel, data: ESModel[], theme: Theme) {
    if (automation.type === AutomationType.Climate) {
        const acMinutes = data.map(i => i as ESThermostat).filter(i => i.maxTemperature && i.currentTemperature && i.maxTemperature < i.currentTemperature).length * 15;
        const heatMinutes = data.map(i => i as ESThermostat).filter(i => i.minTemperature && i.currentTemperature && i.minTemperature > i.currentTemperature).length * 15;
        const areaCharts = acMinutes >= heatMinutes ? [
            { data: data.map(i => i as ESThermostat).map((i, index) => new Point(index, Math.round(i.currentTemperature ?? 0))), color: '#4ab1d9', area: true, label: false },
            { data: data.map(i => i as ESThermostat).map((i, index) => new Point(index, Math.round(i.maxTemperature ?? 0))), color: theme.palette.background.paper, area: true, label: false },
        ] : [
            { data: data.map(i => i as ESThermostat).map((i, index) => new Point(index, Math.round(i.minTemperature ?? 0))), color: 'red', area: true, label: false },
            { data: data.map(i => i as ESThermostat).map((i, index) => new Point(index, Math.round(i.currentTemperature ?? 0))), color: theme.palette.background.paper, area: true, label: false },
        ]

        return {
            Series: [
                ...areaCharts,

                { data: data.map(i => i as ESThermostat).map((i, index) => new Point(index, Math.round(i.currentTemperature ?? 0))), color: 'green', area: false, label: false, thickness: 4 },
                { data: data.map(i => i as ESThermostat).map((i, index) => new Point(index, Math.round(i.maxTemperature ?? 0))), color: '#0d79a3', area: false, label: false, thickness: 2 },
                { data: data.map(i => i as ESThermostat).map((i, index) => new Point(index, Math.round(i.minTemperature ?? 0))), color: 'red', area: false, label: false, thickness: 2 }] as Series[],
            Range: GetRange(65, 81, 1),
            XAxis: { points: data.map(i => dayjs(i.timestamp).format('h a')), maxLabelWidth: 50 } as XAxis,
            YAxis: { Points: GetRange(66, 80, 1), ShowRuler: true } as YAxis,
        };
    }

    if (automation.type === AutomationType.Lights) {
        return {
            Series: [
                { data: data.map(i => i as ESLight).map((i, index) => new Point(index, Math.round(i.brightness ?? 0))), color: 'green', area: false, label: false, thickness: 2 },
            ] as Series[],
            Range: GetRange(-5, 105, 1),
            XAxis: { points: data.map(i => dayjs(i.timestamp).format('h a')), maxLabelWidth: 50 } as XAxis,
            YAxis: { Points: GetRange(0, 100, 10), ShowRuler: true, LabelFormat: p => `${p}` } as YAxis
        };
    }

    if (automation.type === AutomationType.ContactSensors) {
        return {
            Series: [
                {
                    data: data.map(i => i as ESContactSensor).map((i, index) => new Point(index, Math.round(i.temperature ?? 0))),
                    color: '#27a35d',
                    area: false,
                    label: false,
                    thickness: 2
                },
                {
                    data: data.map(i => i as ESContactSensor).map((i, index) => new Point(index, Math.round(i.humidity ?? 0))),
                    color: '#3d8bbf',
                    area: false,
                    label: false,
                    thickness: 2
                },
                {
                    data: data.map(i => i as ESContactSensor).map((i, index) => new Point(index, (i.isOn ?? 0) > 0 ? 1 : 15)),
                    color: '#d97c3b',
                    area: true,
                    label: false,
                    thickness: 2
                },
            ] as Series[],
            Range: GetRange(0, 105, 1),
            XAxis: { points: data.map(i => dayjs(i.timestamp).format('h a')), maxLabelWidth: 50 } as XAxis,
            YAxis: { Points: GetRange(0, 100, 10), ShowRuler: true, LabelFormat: p => `${p}%` } as YAxis
        };
    }

    if (automation.type === AutomationType.CatFeeder) {
        const d = data.map(i => i as ESCatFeeder).map(i => i.totalFoodDrops);
        const min = Math.min(...d);
        const max = Math.max(...d);
        const tenPercent = (max / 10);
        const minR = min - tenPercent * 2;
        const maxR = max + tenPercent * 1;
        return {
            Series: [
                {
                    data: data.map(i => i as ESCatFeeder).map((i, index) => new Point(index, Math.round(i.totalFoodDrops ?? 0))),
                    color: '#7cc4a9',
                    area: false,
                    label: false,
                    thickness: 2
                },
                {
                    data: data.map(i => i as ESCatFeeder).map((i, index) => new Point(index, Math.round((i.servingsLeft ?? 0) + minR + 1))),
                    color: '#d3a6f7',
                    area: false,
                    label: false,
                    thickness: 3
                },
                {
                    data: data.map(i => i as ESCatFeeder).map((i, index) => new Point(index, (i.isEmpty ?? 0) > 0 ? minR + tenPercent : minR + tenPercent * 2)),
                    color: '#d97c3b',
                    area: false,
                    label: false,
                    thickness: 3
                },
                {
                    data: data.map(i => i as ESCatFeeder).map((i, index) => new Point(index, (i.unavailable ?? 0) < .1 ? minR + 1 : minR + tenPercent - 1)),
                    color: '#333333',
                    area: true,
                    label: false,
                    thickness: 2
                },
            ] as Series[],
            Range: GetRange(minR, maxR, 1),
            XAxis: { points: data.map(i => dayjs(i.timestamp).format('h a')), maxLabelWidth: 50 } as XAxis,
            YAxis: { Points: GetRange(minR, maxR, tenPercent).map(i => Math.round(i)), ShowRuler: true, LabelFormat: p => `${p}` } as YAxis
        };
    }

    if (automation.type === AutomationType.Plant) {
        return {
            Series: [
                {
                    data: data.map(i => i as ESPlant).map((i, index) => new Point(index, Math.round(i.waterLevel ?? 0))),
                    color: '#a2edfc',
                    area: true,
                    label: false,
                    thickness: 2
                },
                {
                    data: data.map(i => i as ESPlant).map((i, index) => new Point(index, i.waterMinimum ?? 0)),
                    color: '#eba360',
                    area: true,
                    label: false,
                    thickness: 2
                },
                {
                    data: data.map(i => i as ESPlant).map((i, index) => new Point(index, Math.round(i.waterLevel ?? 0))),
                    color: '#41b057',
                    area: false,
                    label: false,
                    thickness: 5,
                },
            ] as Series[],
            Range: GetRange(0, 100, 1),
            XAxis: { points: data.map(i => dayjs(i.timestamp).format('ddd ha')), maxLabelWidth: 60 } as XAxis,
            YAxis: { Points: GetRange(0, 100, 10), ShowRuler: true, LabelFormat: p => `${p}%` } as YAxis
        };
    }

    return { Series: [], Range: [], XAxis: { points: [] } };
}

function ChartFooter(props: Props) {
    const formatMinutes = (minutes: number) => {
        var m = minutes % 60;
        var h = (minutes - m) / 60;
        return `${Math.round(minutes / 60 * 100) / 100} hours`;
    }

    if (props.automation.type === AutomationType.Climate) {
        const climateData = props.data.map(i => i as ESThermostat);
        const acMinutes = climateData.filter(i => i.maxTemperature && i.currentTemperature && i.maxTemperature < i.currentTemperature).length * 15;
        const heatMinutes = climateData.filter(i => i.minTemperature && i.currentTemperature && i.minTemperature > i.currentTemperature).length * 15
        return <Stack direction='column'>
            <Box sx={{ display: 'inline-flex' }}>
                <Typography>AC Usage:&nbsp;</Typography><Typography fontWeight='bold'>{formatMinutes(acMinutes)}</Typography>
            </Box>
            <Box sx={{ display: 'inline-flex' }}>
                <Typography>Heat Usage:&nbsp;</Typography><Typography fontWeight='bold'>{formatMinutes(heatMinutes)}</Typography>
            </Box>
        </Stack>;
    }

    return <Box></Box>;
}

export function ChartPanel(props: Props) {
    const theme = useTheme();
    const [data, setData] = useState(props.data);
    const [date, setDate] = useState(dayjs());
    const [sensorId, setSensorId] = useState((props.automation as IContactSensorsAutomationModel).sensors?.[0]?.entityId);
    const [graph, setGraph] = useState(getGraph(props.automation, props.data, theme));

    const changeDate = async (day: number) => {
        const newDate = date.add(day, 'day');
        setDate(newDate);
        if (props.automation.type == AutomationType.Climate) {
            var updated = await appService.Automations.getHistory(props.automation.id, null, newDate);
            setData(updated ?? []);
            setGraph(getGraph(props.automation, updated ?? [], theme));
        }
        else if (props.automation.type == AutomationType.Lights) {
            var updated = await appService.Automations.getHistory(props.automation.id, null, newDate);
            setData(updated ?? []);
            setGraph(getGraph(props.automation, updated ?? [], theme));
        }
        else if (props.automation.type === AutomationType.ContactSensors) {
            var updated = await appService.Automations.getHistory(props.automation.id, sensorId, newDate);
            setData(updated ?? []);
            setGraph(getGraph(props.automation, updated ?? [], theme));
        }
        else if (props.automation.type === AutomationType.Plant) {
            setDate(newDate.add(4 * day, 'day'));
            var updated = await appService.Automations.getHistory(props.automation.id, sensorId, newDate.add(-4, 'day'));
            setData(updated ?? []);
            setGraph(getGraph(props.automation, updated ?? [], theme));
        }
        else if (props.automation.type === AutomationType.CatFeeder) {
            var updated = await appService.Automations.getHistory(props.automation.id, null, newDate);
            setData(updated ?? []);
            setGraph(getGraph(props.automation, updated ?? [], theme));
        }
    }

    const selectSensor = async (id: string) => {
        const stats = await appService.Automations.getHistory(props.automation.id, id);
        if (stats) {
            setData(stats);
            setSensorId(id);
            setGraph(getGraph(props.automation, stats, theme));
        }
    }

    return <Box>
        <Typography fontWeight='bold'>{props.automation.title}</Typography>
        {props.automation.type === AutomationType.ContactSensors ?
            <Box width={700} marginTop='8px'>
                {(props.automation as IContactSensorsAutomationModel).sensors.map(sensor =>
                    <Grey.ThemedChip
                        key={sensor.entityId}
                        label={sensor.friendlyName}
                        sx={{ marginRight: '5px', marginBottom: '5px', background: sensorId === sensor.entityId ? theme.action.chipHover : '' }}
                        onClick={() => selectSensor(sensor.entityId)}
                    />
                )}
            </Box>
            : null}
        <GreyGraph
            sx={{ marginTop: '10px', marginBottom: '10px' }}
            series={graph.Series}
            xAxis={graph.XAxis}
            width={700}
            height={400}
            yAxis={graph.YAxis}
            yAxisRange={{ min: graph.Range[0], max: graph.Range[graph.Range.length - 1] }}
            shouldConsolidate={true} />
        <ChartFooter automation={props.automation} data={data} />
        <Typography sx={{ marginY: '5px', textAlign: 'center' }}>{date.format('dddd, MMMM D')}</Typography>
        <Box textAlign='center' sx={{ marginBottom: '10px' }}>
            <Grey.CustomButton onClick={() => changeDate(-1)} sx={{ marginRight: '15px' }} variant="contained">Previous</Grey.CustomButton>
            <Grey.CustomButton onClick={() => changeDate(1)} variant="contained">Next</Grey.CustomButton>
        </Box>
    </Box>
}
