import MenuIcon from '@mui/icons-material/Menu';
import { Slide, useTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import * as Grey from '../StyledComponents';
import { IAutomationModel, RouteInfo } from '../../models/Models';
import GreySpeedDial from './GreySpeedDial';
import { useAppContext } from '../../services/AppContext';

const drawerWidth = 240;

interface Props {
  routes: RouteInfo[];
  refresh: () => void,
  automations: IAutomationModel[],
  changeTheme: (theme: string) => void,
}

export default function ResponsiveDrawerSmall(props: Props) {
  const routeMap = useAppContext().routes;
  const routes = props.routes;
  const theme = useTheme();
  const [slideDirection, setSlideDirection] = React.useState('right');
  const navigate = useNavigate();

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const changeRoute = (route: string) => {
    setMobileOpen(false);
    navigate(route);
  }

  const drawer = (
    <div>
      <Divider />
      <List>
        {routes.map(route => (
          <ListItem key={route.Name} disablePadding>
            <ListItemButton onClick={() => { changeRoute(route.Path) }}>
              <ListItemIcon>
                {route.Icon}
              </ListItemIcon>
              <ListItemText primary={route.Name} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Box sx={{ display: 'flex', backgroundColor: theme.page.mobileBackground }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
          backgroundColor: theme.appBar.background,
          color: theme.appBar.color,
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            {routeMap.getCurrentRouteInfo().Name}
          </Typography>
          {routes.filter(route => route.ShowInTop).map(route =>
            <Grey.CustomIconButton key={route.Path} onClick={() => { changeRoute(route.Path); }} sx={{ marginLeft: '10px' }}>{route.Icon}</Grey.CustomIconButton>)}
          <GreySpeedDial small={true} refresh={props.refresh} automations={props.automations} changeTheme={props.changeTheme} />
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{ flexGrow: 1, p: 0, width: { sm: `calc(100% - ${drawerWidth}px)` }, height: window.innerHeight }}>
        <Toolbar />
        <Outlet />
      </Box>
    </Box>
  );
}
