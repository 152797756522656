import { PauseTwoTone, PlayArrowTwoTone, PowerSettingsNewTwoTone, TvSharp, TvTwoTone, VolumeDown, VolumeUp } from "@mui/icons-material";
import { Box, CardContent, Grid, Slider, Stack, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import { useEffect, useState } from "react";
import { IAutomationModel, IMediaPlayerAutomationModel } from "../../models/Models";
import { appService } from "../../services/AppService";
import { useEventHub } from "../../services/EventHub";
import { IsTouch } from "../../services/Utils";
import * as Grey from "../StyledComponents";
import { SettingsMenuButton } from "../configs/SettingsMenuButton";
import { PanelProps } from "./PanelProps";
import React from "react";

function QuickActions(data: IMediaPlayerAutomationModel, handleAction: (id: string) => void, forRoom?: boolean) {
    if (forRoom) {
        return data.actions?.filter(action => !action.id.startsWith('camera.')).map(action =>
            <Grey.ThemedChip
                key={action.id}
                sx={{ marginRight: '5px', marginTop: '8px' }}
                label={action.actionName}
                variant="outlined"
                onClick={() => handleAction(action.id)}
                aria-label="Default" />)
    }

    return data.actions?.map(action =>
        <Grey.ThemedChip
            key={action.id}
            sx={{ marginRight: '5px', marginTop: '8px' }}
            label={action.actionName}
            variant="outlined"
            onClick={() => handleAction(action.id)}
            aria-label="Default" />)
}

function MediaPlayerPanelSmall(props: PanelProps) {
    const theme = useTheme();
    const [data, setData] = useState(props.data as IMediaPlayerAutomationModel);
    const [volume, setVolume] = useState(data.volumeLevel * 100);
    const [isInactive, setIsInactive] = useState(data.isInactive);

    const togglePower = async () => {
        if (data.mediaState === 'Off') {
            updateAll(await appService.MediaPlayers.turnOn(data.id));
        }
        else {
            updateAll(await appService.MediaPlayers.turnOff(data.id));
        }
    }

    const updateVolume = async (volume: number) => {
        setVolume(volume);
        updateAll(await appService.MediaPlayers.setVolume(data.id, volume / 100));
    }

    const pause = async () => {
        updateAll(await appService.MediaPlayers.togglePause(data.id));
    }

    const updateAll = (updated: IAutomationModel | null) => {
        var model = updated as IMediaPlayerAutomationModel;
        if (model) {
            setData(model);
            setVolume(model.volumeLevel * 100);
            setIsInactive(model.isInactive);
            if (props.updated) props.updated(model);
        }
    }

    const handleSliderChange = async (event: Event, newValue: number | number[]) => {
        setVolume(newValue as number);
    };

    const commitSliderChange = async (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
        await updateVolume(newValue as number);
    };
    const handleAction = async (actionId: string) => {
        updateAll(await appService.MediaPlayers.executeAction(data.id, actionId));
    }
    useEventHub<IMediaPlayerAutomationModel>(data.id, updateAll);

    useEffect(() => {
        updateAll(props.data as IMediaPlayerAutomationModel);
    }, [props.data])

    const sliderStyle = {
        marginLeft: '8px',
        marginRight: '10px',
        marginTop: IsTouch() ? '0px' : '4px',
        verticalAlign: 'center',
        '& .MuiSlider-markLabel': {
            padding: '3px',
        },
        '& .MuiSlider-markLabelActive': {
            background: theme.card.background,
            padding: '3px',
            zIndex: '1000'
        },
        '& .MuiSlider-thumb': {
            width: 8,
            height: 8,
            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
            '&:before': {
                boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
            },
            '&:hover, &.Mui-focusVisible': {
                boxShadow: `0px 0px 0px 8px ${'rgb(255 255 255 / 16%)'
                    }`,
            }
        }
    };

    const toHHMMSS = (n: number) => {
        var hours = Math.floor(n / 3600);
        var minutes = Math.floor((n - (hours * 3600)) / 60);
        var seconds = n - (hours * 3600) - (minutes * 60);

        const h = hours < 10 ? "0" + hours : hours;
        const m = minutes < 10 ? "0" + minutes : minutes;
        const s = seconds < 10 ? "0" + seconds : seconds;
        return (h == 0 ? '' : h + ':') + m + ':' + s;
    }

    const marks = [
        {
            value: data.mediaPosition ?? 0,
            label: toHHMMSS(Math.round(data.mediaPosition ?? 0)),
        },
        {
            value: data.mediaDuration ?? 0,
            label: toHHMMSS(Math.round(data.mediaDuration ?? 0)),
        },
    ];

    if (props.forRoom) {
        return isInactive ? <Box sx={{ flexGrow: 1, position: 'relative', marginLeft: '2px', marginBottom: '10px' }}>
            {QuickActions(data, handleAction, true)}
        </Box> : null;
    }

    return <Box sx={{ flexGrow: 1, position: 'relative' }}>
        <Grey.CardTitleButton onClick={props.handleOpen}>
            <Grey.AutomationTitleSmall className='smallCardTitle' color='secondary' fontSize='14px'>
                {data?.title}
            </Grey.AutomationTitleSmall>
            <TvSharp color='info' sx={{ position: 'absolute', right: '10px' }} />
        </Grey.CardTitleButton>
        {isInactive && (data.actions?.length ?? 0) > 0 ? <Box sx={{ marginTop: '2px', marginBottom: '10px' }}> {QuickActions(data, handleAction)} </Box> :
            <Box sx={{ textAlign: 'left', position: 'relative', marginY: '10px', marginX: '13px' }}>
                <div className='panel' style={{ marginLeft: '-8px', marginRight: '-8px' }}>
                    <Grey.CustomIconButton sx={{ height: '40px', marginTop: IsTouch() ? '2px' : '0px' }} onClick={togglePower}>
                        <PowerSettingsNewTwoTone />
                    </Grey.CustomIconButton>
                    {data.mediaDuration ? <Grey.CustomSlider
                        sx={sliderStyle}
                        marks={marks}
                        value={data.mediaPosition ?? 0}
                        min={0}
                        max={data.mediaDuration ?? 0} /> : null}
                    {!data.isInactive ? <Grey.CustomIconButton onClick={pause} sx={{ float: 'right', height: '40px', marginTop: IsTouch() ? '2px' : '0px' }}>{data.mediaState === 'Playing' ? <PauseTwoTone /> : <PlayArrowTwoTone />}</Grey.CustomIconButton> : null}
                </div>
                <div className='panel' style={{ marginTop: '0px', marginLeft: '-8px', marginRight: '-8px' }}>
                    <Grey.CustomIconButton disabled={data.isInactive} onClick={() => updateVolume(volume - 5)}><VolumeDown /></Grey.CustomIconButton>
                    <Grey.CustomSlider disabled={data.isInactive} sx={sliderStyle} value={volume} min={0} max={100} onChange={handleSliderChange} onChangeCommitted={commitSliderChange} step={1} />
                    <Grey.CustomIconButton disabled={data.isInactive} sx={{ float: 'right' }} onClick={() => updateVolume(volume + 5)}><VolumeUp /></Grey.CustomIconButton>
                </div>
            </Box>
        }
    </Box>
}

function MediaPlayerPanel(props: PanelProps) {
    const [data, setData] = useState(props.data as IMediaPlayerAutomationModel);
    const [volume, setVolume] = useState(data.volumeLevel * 100);
    const theme = useTheme();

    const updateAll = (updated: IMediaPlayerAutomationModel | null) => {
        if (updated) {
            setData(updated);
            setVolume(updated.volumeLevel * 100);
        }
    }

    const togglePower = async () => {
        if (data.mediaState === 'Off') {
            updateAll(await appService.MediaPlayers.turnOn(data.id));
        }
        else {
            updateAll(await appService.MediaPlayers.turnOff(data.id));
        }
    }

    const updateVolume = async (volume: number) => {
        setVolume(volume);
        updateAll(await appService.MediaPlayers.setVolume(data.id, volume / 100));
    }

    const handleAction = async (actionId: string) => {
        updateAll(await appService.MediaPlayers.executeAction(data.id, actionId));
    }

    const pause = async () => {
        updateAll(await appService.MediaPlayers.togglePause(data.id));
    }

    const handleSliderChange = async (event: Event, newValue: number | number[]) => {
        setVolume(newValue as number);
    };

    const commitSliderChange = async (event: React.SyntheticEvent | Event, newValue: number | number[]) => {
        await updateVolume(newValue as number);
    };

    useEventHub<IMediaPlayerAutomationModel>(data.id, updateAll);

    const sliderStyle = {
        marginLeft: '8px',
        marginRight: '10px',
        marginTop: IsTouch() ? '0px' : '4px',
        verticalAlign: 'center',
        '& .MuiSlider-markLabel': {
            padding: '3px',
        },
        '& .MuiSlider-markLabelActive': {
            background: theme.card.background,
            padding: '3px',
            zIndex: '1000'
        },
        '& .MuiSlider-thumb': {
            width: 8,
            height: 8,
            transition: '0.3s cubic-bezier(.47,1.64,.41,.8)',
            '&:before': {
                boxShadow: '0 2px 12px 0 rgba(0,0,0,0.4)',
            },
            '&:hover, &.Mui-focusVisible': {
                boxShadow: `0px 0px 0px 8px ${'rgb(255 255 255 / 16%)'
                    }`,
            }
        }
    };

    const toHHMMSS = (n: number) => {
        var hours = Math.floor(n / 3600);
        var minutes = Math.floor((n - (hours * 3600)) / 60);
        var seconds = n - (hours * 3600) - (minutes * 60);

        const h = hours < 10 ? "0" + hours : hours;
        const m = minutes < 10 ? "0" + minutes : minutes;
        const s = seconds < 10 ? "0" + seconds : seconds;
        return (h == 0 ? '' : h + ':') + m + ':' + s;
    }

    const marks = [
        {
            value: data.mediaPosition ?? 0,
            label: toHHMMSS(Math.round(data.mediaPosition ?? 0)),
        },
        {
            value: data.mediaDuration ?? 0,
            label: toHHMMSS(Math.round(data.mediaDuration ?? 0)),
        },
    ];

    return (
        <Grey.AutomationCard className={`card card-${theme.name}`} variant="outlined">
            <CardContent>
                <Grey.AutomationTitle>
                    {data.title.length > 25 ? (data.title.substring(0, 25) + '...') : data.title}
                    <SettingsMenuButton automation={data} />
                </Grey.AutomationTitle>
                <div style={{ textAlign: 'left', position: 'relative' }}>
                    <div className='panel' style={{ marginLeft: '-8px', marginRight: '-8px' }}>
                        <Grey.CustomIconButton sx={{ height: '40px' }} onClick={togglePower}>
                            <PowerSettingsNewTwoTone />
                        </Grey.CustomIconButton>
                        {data.mediaDuration ? <Grey.CustomSlider
                            sx={sliderStyle}
                            marks={marks}
                            value={data.mediaPosition ?? 0}
                            min={0}
                            max={data.mediaDuration ?? 0} /> : null}
                        {!data.isInactive ? <Grey.CustomIconButton onClick={pause} sx={{ float: 'right', height: '40px' }}>{data.mediaState === 'Playing' ? <PauseTwoTone /> : <PlayArrowTwoTone />}</Grey.CustomIconButton> : null}
                    </div>
                    <div className='panel' style={{ marginTop: '10px', marginLeft: '-8px', marginRight: '-8px' }}>
                        <Grey.CustomIconButton disabled={data.isInactive} onClick={() => updateVolume(volume - 5)}><VolumeDown /></Grey.CustomIconButton>
                        <Grey.CustomSlider disabled={data.isInactive} sx={sliderStyle} value={volume} min={0} max={100} onChange={handleSliderChange} onChangeCommitted={commitSliderChange} step={1} />
                        <Grey.CustomIconButton disabled={data.isInactive} sx={{ float: 'right' }} onClick={() => updateVolume(volume + 5)}><VolumeUp /></Grey.CustomIconButton>
                    </div>
                    <Stack direction='row' sx={{ marginTop: '10px' }}>
                        {data.thumbnail ? <img style={{ marginRight: '10px', maxHeight: '80px', maxWidth: '80px' }} src={data.thumbnail} /> : null}
                        <Typography
                            sx={{ fontSize: 16, textAlign: 'left' }}
                            color="text.secondary"
                            gutterBottom>
                            {data.status}
                        </Typography>
                    </Stack>
                    <Box sx={{ marginTop: '10px' }}>
                        {QuickActions(data, handleAction)}
                    </Box>
                </div>
            </CardContent>
        </Grey.AutomationCard>
    );
}
export { MediaPlayerPanel, MediaPlayerPanelSmall };

