import { Fade, useTheme } from "@mui/material"
import { AutomationType, IAlertsAutomationModel, IAutomationModel, IBatteryAutomationState, IBlindsAutomationModel, IClimateAutomationModel, IGarageAutomationModel, ILightAutomationModel, ILockAutomationModel, IMediaPlayerAutomationModel, ITemperatureAutomationState, IWeatherAutomationModel } from "../../models/Models"
import * as Grey from '../StyledComponents'
import { AlertsPanel, AlertsPanelSmall } from "./AlertsPanel"
import { BatteryPanel, BatteryPanelSmall } from "./BatteryPanel"
import { BlindsPanel, BlindsPanelSmall } from "./BlindsPanel"
import { ClimatePanel, ClimatePanelSmall } from "./ClimatePanel"
import { GaragePanel, GaragePanelSmall } from "./GaragePanel"
import { LightPanel, LightPanelSmall } from "./LightPanel"
import { LockPanel, LockPanelSmall } from "./LockPanel"
import { MediaPlayerPanel, MediaPlayerPanelSmall } from "./MediaPlayerPanel"
import { TemperaturePanel, TemperaturePanelSmall } from "./TemperaturePanel"
import { TimerPanel, TimerPanelSmall } from "./TimersPanel"
import { WeatherPanel, WeatherPanelSmall } from "./WeatherPanel"
import { PlugsPanel, PlugsPanelSmall } from "./PlugsPanel"
import { PresencePanel, PresencePanelSmall } from "./PresencePanel"
import { HealthCheckPanel, HealthCheckPanelSmall } from "./HealthCheckPanel"
import { PlantOverviewPanel, PlantPanel, PlantPanelSmall } from "./PlantsPanel"
import { PrinterPanel, PrinterPanelSmall } from "./PrinterPanel"
import { RemotesPanel, RemotesPanelSmall } from "./RemotesPanel"
import { ContactSensorsPanel, ContactSensorsPanelSmall } from "./ContactSensorsPanel"
import { CatFeederPanel, CatFeederPanelSmall } from "./CatFeederPanel"

export function LargeAutomationCard(props: { data: IAutomationModel, isModal?: boolean }) {
    const theme = useTheme();
    const model = props.data;
    const isModal = props.isModal ?? false;
    if (model.type == AutomationType.Lights && !isModal) {
        return <Grey.AutomationCard key={model.id} className={`card card-${theme.name}`} variant="outlined">
            <LightPanel data={model as ILightAutomationModel} />
        </Grey.AutomationCard>
    }
    else if (model.type === AutomationType.Lights && isModal) {
        return <LightPanel key={model.id} data={model} isModal={isModal} />
    }
    else if (model.type == AutomationType.Climate) {
        return <ClimatePanel data={model as IClimateAutomationModel} />
    }
    else if (model.type == AutomationType.Weather) {
        return <WeatherPanel data={model as IWeatherAutomationModel} />
    }
    else if (model.type == AutomationType.Lock) {
        return <LockPanel data={model as ILockAutomationModel} isModal={isModal} />
    }
    else if (model.type == AutomationType.GarageDoor) {
        return <GaragePanel data={model as IGarageAutomationModel} />
    }
    else if (model.type == AutomationType.Blinds) {
        return <BlindsPanel data={model as IBlindsAutomationModel} />
    }
    else if (model.type == AutomationType.Temperature) {
        return <TemperaturePanel data={model as ITemperatureAutomationState} />
    }
    else if (model.type == AutomationType.Battery) {
        return <BatteryPanel key={model.id} data={model as IBatteryAutomationState} />
    }
    else if (model.type == AutomationType.MediaPlayer) {
        return <MediaPlayerPanel key={model.id} data={model as IMediaPlayerAutomationModel} />
    }
    else if (model.type == AutomationType.Alerts) {
        return <AlertsPanel key={model.id} data={model as IAlertsAutomationModel} />
    }
    else if (model.type == AutomationType.Plugs) {
        return <PlugsPanel key={model.id} data={model} />
    }
    else if (model.type == AutomationType.Timers) {
        return <TimerPanel key={model.id} data={model} />
    }
    else if (model.type == AutomationType.Presence) {
        return <PresencePanel key={model.id} data={model} />
    }
    else if (model.type == AutomationType.HealthCheck) {
        return <HealthCheckPanel key={model.id} data={model} />
    }
    else if (model.type == AutomationType.PlantOverview) {
        return <PlantOverviewPanel key={model.id} data={model} />
    }
    else if (model.type == AutomationType.Plant) {
        return <PlantPanel key={model.id} data={model} />
    }
    else if (model.type == AutomationType.Printer) {
        return <PrinterPanel key={model.id} data={model} />
    }
    else if (model.type == AutomationType.Remotes) {
        return <RemotesPanel key={model.id} data={model} />
    }
    else if (model.type == AutomationType.ContactSensors) {
        return <ContactSensorsPanel key={model.id} data={model} />
    }
    else if (model.type == AutomationType.CatFeeder) {
        return <CatFeederPanel key={model.id} data={model} />
    }

    return <div />;
}

export function SmallAutomationCard(props: {
    data: IAutomationModel,
    handleOpen: () => void,
    updated?: (data: IAutomationModel) => void,
    forRoom?: boolean,
}) {
    const data = props.data;
    const handleOpen = props.handleOpen;
    if (data.type === AutomationType.Lights) {
        return <LightPanelSmall data={data} handleOpen={handleOpen} forRoom={props.forRoom} />
    }
    if (data.type === AutomationType.Alerts) {
        return <AlertsPanelSmall data={data} handleOpen={handleOpen} forRoom={props.forRoom} />
    }
    if (data.type === AutomationType.Timers) {
        return <TimerPanelSmall data={data} handleOpen={handleOpen} forRoom={props.forRoom} />
    }
    if (data.type === AutomationType.MediaPlayer) {
        return <MediaPlayerPanelSmall data={data} handleOpen={handleOpen} updated={props.updated} forRoom={props.forRoom} />
    }
    if (data.type === AutomationType.Blinds) {
        return <BlindsPanelSmall data={data} handleOpen={handleOpen} forRoom={props.forRoom} />
    }
    if (data.type === AutomationType.Battery) {
        return <BatteryPanelSmall data={data} handleOpen={handleOpen} forRoom={props.forRoom} />
    }
    if (data.type === AutomationType.Temperature) {
        return <TemperaturePanelSmall data={data} handleOpen={handleOpen} forRoom={props.forRoom} />
    }
    if (data.type === AutomationType.Weather) {
        return <WeatherPanelSmall data={data} handleOpen={handleOpen} forRoom={props.forRoom} />
    }
    if (data.type === AutomationType.Climate) {
        return <ClimatePanelSmall data={data} handleOpen={handleOpen} forRoom={props.forRoom} />
    }
    if (data.type === AutomationType.Lock) {
        return <LockPanelSmall data={data} handleOpen={handleOpen} forRoom={props.forRoom} />
    }
    if (data.type === AutomationType.GarageDoor) {
        return <GaragePanelSmall data={data} handleOpen={handleOpen} forRoom={props.forRoom} />
    }
    if (data.type === AutomationType.Plugs) {
        return <PlugsPanelSmall data={data} handleOpen={handleOpen} forRoom={props.forRoom} />
    }
    if (data.type === AutomationType.Presence) {
        return <PresencePanelSmall data={data} handleOpen={handleOpen} forRoom={props.forRoom} />
    }
    if (data.type === AutomationType.HealthCheck) {
        return <HealthCheckPanelSmall data={data} handleOpen={handleOpen} forRoom={props.forRoom} />
    }
    if (data.type === AutomationType.Plant) {
        return <PlantPanelSmall data={data} handleOpen={handleOpen} forRoom={props.forRoom} />
    }
    if (data.type === AutomationType.Printer) {
        return <PrinterPanelSmall data={data} handleOpen={handleOpen} forRoom={props.forRoom} />
    }
    if (data.type === AutomationType.Remotes) {
        return <RemotesPanelSmall data={data} handleOpen={handleOpen} forRoom={props.forRoom} />
    }
    if (data.type === AutomationType.ContactSensors) {
        return <ContactSensorsPanelSmall data={data} handleOpen={handleOpen} forRoom={props.forRoom} />
    }
    if (data.type === AutomationType.CatFeeder) {
        return <CatFeederPanelSmall data={data} handleOpen={handleOpen} forRoom={props.forRoom} />
    }

    return <div />;
}