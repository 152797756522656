import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import App from './App';
import { CameraFilesPage, cameraLoader } from './components/pages/CameraFilesPage';
import { CamerasPage } from './components/pages/CamerasPage';
import { AutomationPage, Home, automationLoader, homeLoader } from './components/pages/Home';
import KibanaPage from './components/pages/KibanaPage';
import LogsPage from './components/pages/LogsPage';
import { RoomsPage } from './components/pages/RoomsPage';
import SettingsPage from './components/pages/SettingsPage';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AutomationConfigPage, AutomationIRConfigPage, automationConfigLoader, automationIRConfigLoader } from './components/pages/AutomationConfigPage';
import WatchlistPage from './components/pages/WatchlistPage';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    errorElement: <App error={true} />,
    children: [
      {
        path: '/',
        element: <Home />,
        loader: homeLoader,
      },
      {
        path: 'home',
        element: <Home />,
        loader: homeLoader,
      },
      {
        path: 'rooms',
        element: <RoomsPage />,
      },
      {
        path: 't/:type',
        element: <Home />,
        loader: homeLoader,
      },
      {
        path: 'd/:dashboard',
        element: <Home />,
        loader: homeLoader,
      },
      {
        path: 'd/:dashboard/:automationId',
        element: <AutomationPage />,
        loader: automationLoader,
      },
      {
        path: 'config/:automationId',
        element: <AutomationConfigPage />,
        loader: automationConfigLoader,
      },
      {
        path: 'irconfig/:automationId/:sensorId',
        element: <AutomationIRConfigPage />,
        loader: automationIRConfigLoader,
      },
      {
        path: 'cameras',
        element: <CamerasPage />,
      },
      {
        path: 'cameras/:cameraId',
        element: <CameraFilesPage />,
        loader: cameraLoader,
      },
      {
        path: 'watchlist',
        element: <WatchlistPage />,
      },
      {
        path: 'logs',
        element: <LogsPage />,
      },
      {
        path: 'charts',
        element: <KibanaPage />,
      },
      {
        path: 'settings',
        element: <SettingsPage />,
      },
    ]
  },
])

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
