import { Add, Remove } from "@mui/icons-material";
import { Box, Card, CardContent, Grid, IconButton, Modal, Stack, Step, SxProps, Theme, Typography } from "@mui/material";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import * as Grey from "../StyledComponents";
import { IAutomationModel } from "../../models/Models";

export function DeltaChange(props: {
    sx?: SxProps<Theme>,
    onChange: (value: number) => void,
    format?: (value: number) => string,
    value?: number,
    step: number,
    range: { min: number, max: number }
}) {
    const [value, setValue] = React.useState(props.value ?? 0);

    const increment = () => {
        const newValue = Math.min(value + props.step, props.range.max);
        setValue(newValue);
        props.onChange(newValue);
    }

    const decrement = () => {
        const newValue = Math.max(value - props.step, props.range.min);
        setValue(newValue);
        props.onChange(newValue);
    }

    return <Stack direction='row' sx={{ ...props.sx, alignItems: 'center', textAlign: 'center' }}>
        <Grey.CustomIconButton disabled={value <= props.range.min} onClick={() => decrement()}><Remove /></Grey.CustomIconButton>
        <Typography sx={{ width: '50px' }}>{props.format ? props.format(value) : value}</Typography>
        <Grey.CustomIconButton disabled={value >= props.range.max} onClick={() => increment()}><Add /></Grey.CustomIconButton>
    </Stack>;
}
