import { Breadcrumbs, Card, CardActionArea, CardContent, CardMedia, Typography, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { MediaList } from '../../models/Models';
import { appService } from '../../services/AppService';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import * as Grey from '../StyledComponents';

//@ts-ignore
export async function cameraLoader({ params }) {
    //@ts-ignore
    return { cameraId: params.cameraId };
}

export function CameraFilesPage() {
    const navigate = useNavigate();
    const params = useLoaderData() as { cameraId?: string };
    const [files, setFiles] = useState<MediaList | null>(null);

    useEffect(() => {
        async function load() {
            try {
                if (!files) {
                    setFiles(await appService.getCameraFiles(params.cameraId ?? ""));
                }
            } catch (error) {

            }
        }

        load();
    }, []);

    if (files == null) {
        return <div />
    }

    return (
        <div>
            <Breadcrumbs sx={{ marginTop: '10px', marginLeft: '20px' }}>
                <Grey.CustomButton sx={{ textDecoration: 'underline' }} onClick={() => navigate('/')}>Home</Grey.CustomButton>
                <Grey.CustomButton sx={{ textDecoration: 'underline' }} onClick={() => navigate('/cameras')}>Cameras</Grey.CustomButton>
                <Typography color="text.secondary">{params.cameraId?.substring(params.cameraId?.indexOf('.') + 1)}</Typography>
            </Breadcrumbs>
            <div className='grid' style={{ marginLeft: '10px' }}>
                {files.items?.map(file => {
                    return <Card key={file.fileName} variant="outlined" sx={{ marginRight: '15px', marginBottom: '16px' }}>
                        <CardActionArea>
                            <CardMedia height={250} width={250 * (16 / 9)} component='video' image={file.fileName} controls />
                        </CardActionArea>
                        <CardContent>
                            <Typography>{file.prettyTimestamp}</Typography>
                        </CardContent>
                    </Card>
                }
                )}
            </div>
        </div>
    );
}
