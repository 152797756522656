import { Box, CardContent, Modal, Theme, useTheme } from '@mui/material';
import * as React from 'react';
import { AutomationType, IAutomationModel } from '../../models/Models';
import { useAppContext } from '../../services/AppContext';
import { eventHubInstance, useEventHub } from '../../services/EventHub';
import { ThemeService } from '../../services/ThemeService';
import * as Grey from "../StyledComponents";
import { SmallAutomationCard, LargeAutomationCard } from '../cards/CardMap';
import { IsAndroidApp } from '../../services/Utils';
import { useNavigate } from 'react-router-dom';
import { RoomPanelSmall } from '../cards/RoomPanel';
import { LightSwitchPanel } from '../cards/LightPanel';

interface Props {
    automation: IAutomationModel;
    lightSwitches?: boolean;
}

export function CardModal(props: Props) {

    const [data, setData] = React.useState(props.automation);
    const theme = useTheme();

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
        // if (IsAndroidApp()) {
        //     setOpen(true);
        //     return;
        // }

        // navigate(`/d/${routes.getCurrentRouteInfo().Name}/${props.automation.id}`);
    }
    const handleClose = () => setOpen(false);

    useEventHub<IAutomationModel>(data.id, (updated) => setData(updated));

    if (props.lightSwitches) {
        return <Grey.AutomationCard className={`smallCard-${theme.name}`} sx={{ width: '100%', marginBottom: '0px', '& .MuiCardContent-root:last-child': { paddingBottom: '0' } }} variant="outlined">
            <CardContent sx={{ padding: 0 }}>
                <LightSwitchPanel data={props.automation} handleOpen={handleOpen} />
                <Modal
                    sx={{ right: IsAndroidApp() ? '23px' : '0' }}
                    open={open}
                    onClose={handleClose}>
                    <Box sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        width: '90%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: theme.card.modalBackground,
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 0,
                    }}>
                        {ThemeService.GetBackgroundImageSmall(theme, 1)}
                        <LargeAutomationCard data={data} isModal={true} />
                        <Grey.CustomButton onClick={handleClose} variant='contained' sx={{ float: 'right', margin: '10px' }} >Done</Grey.CustomButton>
                    </Box>
                </Modal>
            </CardContent>
        </Grey.AutomationCard>;
    }

    if (data.type == AutomationType.Lights) {
        return <Grey.AutomationCard className={`smallCard-${theme.name}`} sx={{ width: '100%', marginBottom: '8px', '& .MuiCardContent-root:last-child': { paddingBottom: '0' } }} variant="outlined">
            <CardContent sx={{ padding: 0 }}>
                <SmallAutomationCard data={props.automation} handleOpen={handleOpen} />
                <Modal
                    sx={{ right: IsAndroidApp() ? '23px' : '0' }}
                    open={open}
                    onClose={handleClose}>
                    <Box sx={{
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        width: '90%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: theme.card.modalBackground,
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 0,
                    }}>
                        {ThemeService.GetBackgroundImageSmall(theme, 1)}
                        <LargeAutomationCard data={data} isModal={true} />
                        <Grey.CustomButton onClick={handleClose} variant='contained' sx={{ float: 'right', margin: '10px' }} >Done</Grey.CustomButton>
                    </Box>
                </Modal>
            </CardContent>
        </Grey.AutomationCard>;
    }

    if (data.type === AutomationType.Alerts) {
        return <SmallAutomationCard data={props.automation} handleOpen={() => setOpen(true)} updated={d => setData(d)} />
    }

    return <Grey.AutomationCard className={`smallCard-${theme.name}`} sx={{ width: '100%', marginBottom: '8px', '& .MuiCardContent-root:last-child': { paddingBottom: '0' } }} variant="outlined">
        <CardContent sx={{ padding: 0 }}>
            <SmallAutomationCard data={props.automation} handleOpen={() => setOpen(true)} updated={d => setData(d)} />
            <Modal
                sx={{ right: IsAndroidApp() ? '23px' : '0' }}
                open={open}
                onClose={handleClose}>
                <Box sx={{
                    position: 'absolute' as 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    p: 0,
                }}><LargeAutomationCard data={data} isModal={true} /></Box>
            </Modal>
        </CardContent>
    </Grey.AutomationCard>;
}
