import { Box, Card, CardContent, ToggleButton, ToggleButtonGroup, Typography, useTheme } from '@mui/material';
import React, { useState } from 'react';
import { Scene, SettingsModel } from '../../models/Models';
import { useAppContext } from '../../services/AppContext';
import { appService } from '../../services/AppService';
import { configService } from '../../services/ConfigService';
import { SettingsMenuButton } from '../configs/SettingsMenuButton';
import { ThemeService } from '../../services/ThemeService';

export default function SettingsPage() {
    const { automations, settings: settingsContext } = useAppContext();
    const [settings, setSettings] = useState<SettingsModel | null>(settingsContext);
    const theme = useTheme();

    const handleMode = async (
        event: React.MouseEvent<HTMLElement>,
        newMode: string,
    ) => {
        if (newMode && settings) {
            var updated = await configService.setSettings({ ...settings, mode: newMode });
            setSettings(updated);
        }
    };

    const changeScene = async (
        event: React.MouseEvent<HTMLElement>,
        newMode: Scene,
    ) => {
        await appService.Scenes.toggle(newMode);
    };
    return (
        <div className='grid'>
            <Card className={`card card-${theme.name}`} variant="outlined">
                <CardContent sx={{ paddingTop: 1 }}>
                    <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                        Modes
                    </Typography>
                    <Box sx={{ textAlign: 'left' }}>
                        <ToggleButtonGroup
                            sx={{ flexWrap: 'wrap', marginTop: '10px' }}
                            exclusive
                            color="info"
                            value={settings?.mode as string}
                            onChange={handleMode}
                            size="small">
                            {settings?.supportedModes?.map(m =>
                                <ToggleButton value={m} aria-label="Default">
                                    {m}
                                </ToggleButton>)}
                        </ToggleButtonGroup>
                    </Box>
                </CardContent>
            </Card>
            <Card className={`card card-${theme.name}`} variant="outlined">
                <CardContent sx={{ paddingTop: 1 }}>
                    <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                        Scenes
                    </Typography>
                    <Box sx={{ textAlign: 'left' }}>
                        <ToggleButtonGroup
                            sx={{ flexWrap: 'wrap', marginTop: '10px' }}
                            color="info"
                            onChange={changeScene}
                            size="small">
                            <ToggleButton value={Scene.Maid} aria-label="Default">
                                {Scene.Maid}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                </CardContent>
            </Card>
            {automations?.map(automation => {
                return <Card className={`card card-${theme.name}`} variant="outlined">
                    <CardContent sx={{ paddingTop: 1 }}>
                        <Typography sx={{ textAlign: 'left', fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                            {automation.title}
                            <SettingsMenuButton automation={automation} />
                        </Typography>
                    </CardContent>
                </Card>
            }
            )}
        </div>
    );
}
