import { Box, Modal, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import * as Grey from "../StyledComponents";
import { IAutomationModel } from "../../models/Models";
import { IRCommandsPanel } from "../configs/IRCommandsPanel";

export function IrCommandsModal(props: { automation: IAutomationModel, irSensorId: string, isOpen: boolean, onClose: () => void }) {

    const theme = useTheme();

    const styleSmall = {
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        paddingTop: 4,
        paddingLeft: 4,
        paddingRight: 4,
    };
    const styleLarge = {
        position: 'absolute' as 'absolute',
        top: '40%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        paddingTop: 4,
        paddingLeft: 4,
        paddingRight: 4,
    };

    return <Modal
        open={props.isOpen}
        onClose={props.onClose}>
        <Box>
            <Box sx={{ display: { xs: 'block', sm: 'block', md: 'none' } }}>
                <Box sx={styleSmall}>
                    <Typography color={theme.palette.text.primary} id="modal-modal-title" variant="h6" component="h2">
                        {props.automation.title}
                    </Typography>
                    <IRCommandsPanel automation={props.automation} type={props.automation.type} sensorId={props.irSensorId} />
                </Box>
            </Box>
            <Box sx={{ display: { xs: 'none', sm: 'none', md: 'block', lg: 'block', xl: 'block' } }}>
                <Box sx={styleLarge}>
                    <Typography color={theme.palette.text.primary} id="modal-modal-title" variant="h6" component="h2">
                        {props.automation.title}
                    </Typography>
                    <IRCommandsPanel automation={props.automation} type={props.automation.type} sensorId={props.irSensorId} />
                    <Grey.CustomButton onClick={() => props.onClose()} variant="contained" sx={{ float: 'right', marginBottom: '15px' }}>Done</Grey.CustomButton>
                </Box>
            </Box>
        </Box>
    </Modal>
}