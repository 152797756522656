import { Add, Remove } from "@mui/icons-material";
import { Box, Card, CardContent, Grid, IconButton, Modal, SxProps, Theme, Typography } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import * as Grey from "../StyledComponents";
import { IAutomationModel } from "../../models/Models";

export function TemporarySchedule(props: {
    sx?: SxProps<Theme>,
    automation: IAutomationModel,
    onSave: (time: number | null) => void,
    buttonContent: string,
    okText: string,
    message: string,
    formOnly?: boolean,
}) {

    const [tempScheduleOpen, setTempScheduleOpen] = React.useState(false);
    const [lockTime, setLockTime] = React.useState<number>(30);
    const [lockDateTime, setLockDateTime] = React.useState(dayjs().add(lockTime, "m"));

    const handleOpen = () => {
        setLockTime(30);
        setLockDateTime(dayjs().add(lockTime, "m"));
        setTempScheduleOpen(true);
    }

    const saveTime = () => {
        if (lockTime && lockTime > 0) {
            props.onSave(lockTime);
        }
        else {
            props.onSave(null);
        }

        setTempScheduleOpen(false);
    }

    const incrementLockTime = () => {
        if (lockTime <= 15) {
            setLockTime(lockTime + 5);
            setLockDateTime(dayjs().add(lockTime + 5, "m"))
        }
        else if (lockTime === 20) {
            setLockTime(lockTime + 10);
            setLockDateTime(dayjs().add(lockTime + 10, "m"))
        }
        else {
            setLockTime(lockTime + 15);
            setLockDateTime(dayjs().add(lockTime + 15, "m"))
        }
    }

    const decrementLockTime = () => {
        if (lockTime === 5) {
            return;
        }
        if (lockTime <= 20) {
            setLockTime(lockTime - 5);
            setLockDateTime(dayjs().add(lockTime - 5, "m"))
        }
        else {
            setLockTime(lockTime - 15);
            setLockDateTime(dayjs().add(lockTime - 15, "m"))
        }
    }

    if (props.formOnly) {
        return <Grid sx={{ marginLeft: '0px', marginTop: '10px' }} container spacing={1}>
            <Grid xs={8} md={8}>
                <Typography>{props.message} for {lockTime} minutes</Typography>
                <Typography sx={{ marginTop: '10px', color: '#5cb56e' }}>Until {lockDateTime.format('h:mm A')}</Typography>
            </Grid>
            <Grid xs={4} md={4}>
                <Box sx={{ float: 'right' }}>
                    <IconButton onClick={() => decrementLockTime()}><Remove /></IconButton>
                    <IconButton onClick={() => incrementLockTime()}><Add /></IconButton>
                </Box>
            </Grid>
            <Grid xs={6} md={8}>
            </Grid>
            <Grid xs={6} md={4}>
                <Grey.CustomButton variant='outlined' sx={{ float: 'right' }} onClick={saveTime}>{props.okText}</Grey.CustomButton>
            </Grid>
        </Grid>;
    }

    return <React.Fragment>
        <Grey.CustomButton onClick={handleOpen} sx={{ ...props.sx }}>{props.buttonContent}</Grey.CustomButton>
        <Modal
            open={tempScheduleOpen}
            onClose={() => setTempScheduleOpen(false)}>
            <Box sx={{
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                width: '350px',
                transform: 'translate(-50%, -50%)',
                border: '2px solid #000',
                boxShadow: 24,
                p: 0,
            }}>
                <Card>
                    <CardContent sx={{ marginRight: '10px' }}>
                        <Grey.AutomationTitle>{props.automation.title}</Grey.AutomationTitle>
                        <Grid sx={{ marginLeft: '0px', marginTop: '10px' }} container spacing={1}>
                            <Grid xs={8} md={8}>
                                <Typography>{props.message} for {lockTime} minutes</Typography>
                                <Typography sx={{ marginTop: '10px', color: '#5cb56e' }}>Until {lockDateTime.format('h:mm A')}</Typography>
                            </Grid>
                            <Grid xs={4} md={4}>
                                <Box sx={{ float: 'right' }}>
                                    <Grey.CustomIconButton onClick={() => decrementLockTime()}><Remove /></Grey.CustomIconButton>
                                    <Grey.CustomIconButton onClick={() => incrementLockTime()}><Add /></Grey.CustomIconButton>
                                </Box>
                            </Grid>
                            <Grid xs={6} md={8}>
                            </Grid>
                            <Grid xs={6} md={4}>
                                <Grey.CustomButton variant='outlined' sx={{ float: 'right' }} onClick={saveTime}>{props.okText}</Grey.CustomButton>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Box>
        </Modal>
    </React.Fragment>

}
