import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { IAutomationModel } from "../models/Models";
import React, { useContext, useEffect } from "react";
import { AppService } from "./AppService";
import { Map } from "typescript";
import { delay } from "./Utils";

export function useEventHub<T>(automationId: string, onEvent: (updated: T) => void) {

    useEffect(() => {
        const key = crypto.randomUUID();
        eventHubInstance.listen(automationId, key, updated => onEvent(updated as T));
        return () => {
            eventHubInstance.unlisten(automationId, key);
        }
    });
}

export function useEventHubGlobal(onEvent: (updated: IAutomationModel[]) => void) {
    useEffect(() => {
        const key = crypto.randomUUID();
        eventHubInstance.listenToGlobal(key, updated => onEvent(updated));
        return () => {
            eventHubInstance.unlistenToGlobal(key);
        }
    });
}

type ActionMetadata = {
    automatonId: string;
    key: string;
    callback: (arg: IAutomationModel) => void;
}
type ActionList = Record<string, ActionMetadata[]>;

export class EventHub {
    private hub?: HubConnection | null;
    private actions: ActionList;
    private globalActions: Record<string, (arg: IAutomationModel[]) => void>;

    constructor() {
        //@ts-ignore
        if (this.hub) {
            throw "can only create one instance"
        }

        this.actions = {};
        this.globalActions = {};
        this.Reconnect();
    }

    public listen(automatonId: string, key: string, callback: (value: IAutomationModel) => void) {
        var existing = this.actions[automatonId];
        if (existing) {
            this.actions[automatonId] = existing.concat({ automatonId, key, callback }).slice(-8);
            return;
        }

        this.actions[automatonId] = [{ automatonId, key, callback }];
    }

    public unlisten(id: string, key: string) {
        const a = this.actions[id];
        if (a) {
            this.actions[id] = a.filter(i => i.key != key);
        }
    }

    public listenToGlobal(key: string, callback: (value: IAutomationModel[]) => void) {
        this.globalActions[key] = callback;
    }

    public unlistenToGlobal(id: string) {
        delete this.globalActions[id];
    }

    private messageReceived(message: string) {
        let updated: IAutomationModel = JSON.parse(message);
        if (!updated) {
            return;
        }

        const callbacks = this.actions[updated.id];
        if (callbacks) {
            callbacks.forEach(ele => ele.callback(updated))
        }

        const all = this.actions["*"];
        if (all) {
            all.forEach(ele => ele.callback(updated))
        }
    }

    private globalUpdateReceived(message: string) {
        let updated: IAutomationModel[] = JSON.parse(message);
        if (!updated) {
            return;
        }

        Object.values(this.globalActions).forEach(ele => ele(updated))
    }

    public async Reconnect() {
        if (this.hub && (this.hub.state == HubConnectionState.Connected || this.hub.state == HubConnectionState.Connecting)) {
            console.log('event socket already connected');
            return;
        }

        this.hub = new HubConnectionBuilder()
            .withUrl(AppService.Host + "notifications")
            .withAutomaticReconnect()
            .build();

        try {
            console.log('attempting to connect to socket...');
            await this.hub.start();

            this.hub.on("updateAutomation", message => {
                this.messageReceived(message);
            });

            this.hub.on("updateAutomations", message => {
                this.globalUpdateReceived(message);
            });

            return;
        }
        catch {
        }
    }
}

export const eventHubInstance = new EventHub();
